export default {
    // *** Before component mount ***
    beforeMount(el, binding) {
        // *** Define resize event function and return value or call main function ***
        el.resizeEvent = () => {
            binding.value();
        };

        // *** Create resize event with resizeEvent callback ***
        window.addEventListener("resize", el.resizeEvent);
    },

    // *** When Component unmounted ***
    unmounted(el) {
        // *** Remove resize event ***
        window.removeEventListener("resize", el.resizeEvent);
    }
};

