<script setup>
// *** Vue ***
import { defineProps } from "vue";

// *** Props ***
const props = defineProps({
    text: {
        type: String,
        required: true
    },
    listColor: {
        type: String,
        default: "var(--main-color)"
    },
    textColor: {
        type: String,
        default: "var(--third-text-color)"
    },
    margin: {
        type: String,
        default: "64px auto 0"
    },
    useContainer: {
        type: Boolean,
        default: false
    }
});

// *** Empty list custom style ***
const emptyListCustomStyle = () => ({
    margin: props.margin,
    "--list-color": props.listColor
});

// *** Empty list custom styles ***
const emptyListStyles = { ...emptyListCustomStyle() };
</script>

<template>
    <div :class="props.useContainer ? 'dashbaord-empty-list-container' : 'dashboard-empty-list'">
        <div class="dashboard-empty-list-utility" :style="emptyListStyles">
            <Typography
                variant="heading6"
                fontWeight="bold"
                :color="props.textColor"
                margin="0 0 16px"
                align="center"
                >{{ props.text }}</Typography
            >
            <div class="dashboard-empty-list-skeleton-items-utility">
                <div class="dashboard-empty-list-skeleton-item-utility">
                    <div class="dashboard-empty-list-skeleton-item-circular-utility"></div>
                    <div class="dashboard-empty-list-skeleton-item-text-box-utility">
                        <div class="dashboard-empty-list-skeleton-item-title-utility"></div>
                        <div class="dashboard-empty-list-skeleton-item-subtitle-utility"></div>
                    </div>
                </div>
                <div class="dashboard-empty-list-skeleton-item-utility">
                    <div class="dashboard-empty-list-skeleton-item-circular-utility"></div>
                    <div class="dashboard-empty-list-skeleton-item-text-box-utility">
                        <div class="dashboard-empty-list-skeleton-item-title-utility"></div>
                        <div class="dashboard-empty-list-skeleton-item-subtitle-utility"></div>
                    </div>
                </div>
                <div class="dashboard-empty-list-skeleton-item-utility">
                    <div class="dashboard-empty-list-skeleton-item-circular-utility"></div>
                    <div class="dashboard-empty-list-skeleton-item-text-box-utility">
                        <div class="dashboard-empty-list-skeleton-item-title-utility"></div>
                        <div class="dashboard-empty-list-skeleton-item-subtitle-utility"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

