<script setup>
// *** Vue ***
import { reactive } from "vue";

// *** Router ***
import { useRouter } from "vue-router";

// *** Tanstack vue query ***
import { useMutation, useQuery } from "@tanstack/vue-query";

// *** Query and Mutation functions(callback) ***
import { domainListFn } from "@/api/dashbaord/queries/dashboardAPIQueriesFn";
import { domainManageListFn } from "@/api/dashbaord/queries/dashboardAPIQueriesFn";

// *** Router instance ***
const router = useRouter();

// *** Reactive states ***
const domainPagination = reactive({
    totalPages: 1,
    activePage: 1
});
let domainResults = reactive([]);

// *** Domain list query ***
const {
    isFetching: domainListIsFetching,
    isLoading: domainListIsLoading,
    isFetched: domainListIsFetched,
    refetch: domainListRefetch
} = useQuery({
    queryKey: ["domain-list-query", domainPagination.activePage],
    queryFn: () => domainListFn({ page: domainPagination.activePage }),
    onSuccess(data) {
        domainPagination.totalPages = Math.ceil(data.total / 5);
        domainResults = Object.assign(domainResults, data.results);
        data.results.forEach((domain) => {
            if (domain.dnsStatus && domain.domainStatus) {
                domainManageListMutateAsync({ domain_id: domain.id });
            }
        });
    }
});

// *** Domain manage list mutation ***
const { isLoading: domainManageListIsLoading, mutate: domainManageListMutateAsync } = useMutation({
    mutationKey: ["domain-manage-list"],
    mutationFn: domainManageListFn,
    onSuccess(data, payload) {
        const findDomainResultIndex = domainResults.findIndex((value) => value.id === payload.domain_id);
        if (data.message !== null) {
            domainResults[findDomainResultIndex] = {
                ...domainResults[findDomainResultIndex],
                incoming: data.message.incoming,
                outgoing: data.message.outgoing,
                bounces: data.message.bounces,
                held: data.message.held
            };
        }
    }
});

// *** Status text by dns and domain ***
const statusTextByDNSAndDomain = (dnsStatus, domainStatus) => {
    if (dnsStatus && domainStatus) return "Approved";
    if (dnsStatus && !domainStatus) return "Under review";
    return "Not verified";
};

// *** Chip type by status ***
const chipTypeByStatus = (dnsStatus, domainStatus) => {
    if (dnsStatus && domainStatus) return "success";
    if (dnsStatus && !domainStatus) return "info";
    return "error";
};

// *** Change route ***
const changeRoute = (route, domainID, domainName, inboxTracker = null) => {
    if (inboxTracker === null || inboxTracker === false) {
        router.push(`/${route}/${domainID}/?domain=${domainName}`);
    } else {
        router.push(`/${route}/${domainID}/?domain=${domainName}&inboxTracker=${inboxTracker}`);
    }
};

// *** Change domain page ***
const changeDomainPage = (activePage) => {
    domainPagination.activePage = activePage;
    domainListRefetch();
};

// *** Table header (extra data) ***
const tableHeader = [
    { id: 1, text: "Domain", minWidth: "210px", maxWidth: "250px" },
    { id: 2, text: "Status", minWidth: "130px", maxWidth: "150px" },
    { id: 2, text: "Processed", minWidth: "100px", maxWidth: "100px" },
    { id: 3, text: "Delivered", minWidth: "100px", maxWidth: "100px" },
    { id: 4, text: "Rejected", minWidth: "100px", maxWidth: "100px" },
    { id: 5, text: "Received", minWidth: "100px", maxWidth: "100px" },
    { id: 6, text: "Actions", minWidth: "130px", maxWidth: "160px" }
    // { id: 4, text: "Actions", minWidth: "130px", maxWidth: "160px" }
];
</script>

<template>
    <DashboardContainer class="dashboard-domains-list">
        <div class="dashboard-domains-list-header">
            <Typography variant="heading5" fontWeight="bold" color="var(--secondary-text-color)"
                >List Of Domains</Typography
            >
        </div>

        <Divider color="var(--background-color)" :radius="0" />

        <!-- #TODO 👉 add loader -->
        <Table
            emptyListText="Waiting for add a domain, Add a domain"
            :tableShow="domainListIsFetched && domainResults.length > 0"
            :headers="tableHeader"
            :isLoading="domainListIsFetching || domainListIsLoading || domainManageListIsLoading"
        >
            <template v-slot:row>
                <tr
                    v-for="(list, index) in domainResults"
                    :key="index"
                    @click="
                        changeRoute(
                            list.dnsStatus && list.domainStatus ? 'domain-management' : 'domain-verification',
                            list.id,
                            list.domain,
                            list.inboxTracker
                        )
                    "
                >
                    <td
                        class="table-body-domain-name"
                        :style="{
                            'min-width': tableHeader[0].minWidth
                        }"
                    >
                        <div class="table-body-flex-box">
                            <Typography variant="body2" :title="list.domain">{{ list.domain }}</Typography>
                        </div>
                    </td>
                    <td :style="{ 'min-width': tableHeader[1].minWidth, 'max-width': tableHeader[1].maxWidth }">
                        <Chip
                            :type="chipTypeByStatus(list.dnsStatus, list.domainStatus)"
                            :text="statusTextByDNSAndDomain(list.dnsStatus, list.domainStatus)"
                        />
                    </td>
                    <td
                        v-text="list.held ? Number(list.held).toLocaleString() : 0"
                        :style="{ 'min-width': tableHeader[2].minWidth, 'max-width': tableHeader[2].maxWidth }"
                    ></td>
                    <td
                        v-text="list.outgoing ? Number(list.outgoing).toLocaleString() : 0"
                        :style="{ 'min-width': tableHeader[3].minWidth, 'max-width': tableHeader[3].maxWidth }"
                    ></td>
                    <td
                        v-text="list.bounces ? Number(list.bounces).toLocaleString() : 0"
                        :style="{ 'min-width': tableHeader[4].minWidth, 'max-width': tableHeader[4].maxWidth }"
                    ></td>
                    <td
                        v-text="list.incoming ? Number(list.incoming).toLocaleString() : 0"
                        :style="{ 'min-width': tableHeader[5].minWidth, 'max-width': tableHeader[5].maxWidth }"
                    ></td>
                    <td
                        class="table-body-actions table-body-flex-box"
                        :style="{ 'min-width': tableHeader[6].minWidth }"
                    >
                        <Button
                            v-if="list.dnsStatus && list.domainStatus"
                            size="small"
                            @click="changeRoute('domain-management', list.id, list.domain, list.inboxTracker)"
                            >Manage</Button
                        >
                        <Button
                            v-else
                            size="small"
                            @click="changeRoute('Dashboard/Domain-Management', list.id, list.domain)"
                            >Verify Domain</Button
                        >
                    </td>
                </tr>
            </template>
        </Table>

        <Pagination
            v-show="
                domainListIsFetched &&
                domainPagination.totalPages > 1 &&
                !domainListIsFetching &&
                !domainListIsLoading &&
                !domainManageListIsLoading
            "
            :total="domainPagination.totalPages"
            @update="changeDomainPage"
            margin="20px 0 0"
        />
    </DashboardContainer>
</template>
