<script setup>
// *** Vue ***
import { defineProps, ref, watch } from "vue";

// *** Tanstack vue query ***
import { useQuery } from "@tanstack/vue-query";

// *** Query functions(callback) ***
import { domainChartInfoByIDFn } from "@/api/dashbaord/queries/dashboardAPIQueriesFn";

// *** Props ***
const props = defineProps({
    domainID: {
        type: [Number, null],
        required: true
    },
    domainName: {
        type: [String, null],
        required: true
    }
});

// *** Reactive state ***
const chartOptions = ref({
    colors: ["#439a97"],
    chart: {
        type: "area",
        height: 350,
        zoom: {
            enabled: true
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: "straight"
    },
    labels: [],
    xaxis: {
        opposite: true
    },
    yaxis: {
        opposite: true
    },
    legend: {
        horizontalAlign: "left"
    }
});
const series = ref([
    {
        name: "Deliverability",
        data: []
    }
]);

// *** Domain chart info by id query ***
// #TODO 👉 add loader

const {
    isFetching: domainChartInfoIsFetching,
    isLoading: domainChartInfoIsLoading,
    refetch: refetchDomainChartInfo
} = useQuery({
    queryKey: ["domain-chart-info-query", props.domainID],
    queryFn: () => domainChartInfoByIDFn({ domain_id: props.domainID }),
    enabled: () => !!props.domainID,
    onSuccess(data) {
        const series = data.map((val) => String(val.total).toLocaleString()) || [];
        const labels = data.map((val) => val.ForDate) || [];
        chartResult(series, labels);
    }
});

// *** Watch domain id and refetch domain chart info query ***
watch(
    () => props.domainID,
    () => refetchDomainChartInfo()
);

// *** Save chart result ***
const chartResult = (seriesData, labelsData) => {
    series.value = [
        {
            name: "Deliverability",
            data: seriesData
        }
    ];
    chartOptions.value = {
        colors: ["#439a97"],
        chart: {
            type: "area",
            height: 400,
            zoom: {
                enabled: true
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: "straight"
        },
        labels: labelsData,
        xaxis: {
            opposite: true
        },
        yaxis: {
            opposite: true
        },
        legend: {
            horizontalAlign: "left"
        }
    };
};
</script>

<template>
    <div class="dashboard-main-deliverability">
        <Typography variant="heading6" fontWeight="700" margin="0" padding="8px 0 0">Deliverability</Typography>
        <div class="veliverability-chart">
            <Loading v-if="domainChartInfoIsFetching || domainChartInfoIsLoading" margin="-50px 0 20px" />
            <apexchart v-else type="area" width="100%" height="100%" :options="chartOptions" :series="series" />
        </div>
    </div>
</template>
