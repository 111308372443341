<script setup>
// *** Vue ***
import { defineProps, ref, reactive, computed, onMounted } from "vue";

// *** Vue router ***
import { useRoute, useRouter } from "vue-router";

// *** Tanstack vue query ***
import { useMutation } from "@tanstack/vue-query";

// *** mutation funciton(callback) ***
import { verifyDomainFn } from "@/api/dashbaord/mutations/dashboardAPIMutationsFn.js";

// *** Props ***
const props = defineProps({
    title: {
        type: String,
        default: ""
    },
    description: {
        type: String,
        default: ""
    },
    parent: {
        type: String,
        required: true
    },
    setupData: {
        type: Object,
        required: true
    }
});

// *** Router instance ***
const router = useRouter();

// *** Route instance ***
const route = useRoute();

// *** Ref states ***
const domainName = ref("");
const domainID = ref(null);
const spfRecordSituation = ref("pending"); // pending - success - failed
const dkimRecordSituation = ref("pending");
const returnPathSituation = ref("pending");
const dkim_record_name = ref("everp-api._domainkey");

// *** Reactive states ***
const alertContents = reactive({
    spf: {
        situation: false,
        content: "",
        type: ""
    },
    dkim: {
        situation: false,
        content: "",
        type: ""
    },
    path: {
        situation: false,
        content: "",
        type: ""
    }
});

// *** verify domain mutation ***
const { isLoading: verifyDomainIsLoading, mutate: verifyDomainMutate } = useMutation({
    mutationKey: ["verify-domain-mutation"],
    mutationFn: verifyDomainFn,
    onSuccess(data) {
        // *** Destruct data error response ***
        const { spf_status, spf_error, dkim_status, dkim_error, return_path_status, return_path_error } = data.Error;

        // *** Spf ***
        if (spf_status != true) {
            spfRecordSituation.value = "failed";
            alertContents.spf.situation = true;
            alertContents.spf.type = "error";
            alertContents.spf.content = `<b>SPF Error</b>: ${spf_error}`;
        } else {
            spfRecordSituation.value = "success";
            alertContents.spf.situation = true;
            alertContents.spf.type = "success";
            alertContents.spf.content = "<b>SPF</b>: SPF Record works properly";
        }

        // *** DKIM ***
        if (dkim_status != true) {
            dkimRecordSituation.value = "failed";
            alertContents.dkim.situation = true;
            alertContents.dkim.type = "error";
            alertContents.dkim.content = `<b>DKIM Error</b>: ${dkim_error}`;
        } else {
            dkimRecordSituation.value = "success";
            alertContents.dkim.situation = true;
            alertContents.dkim.type = "success";
            alertContents.dkim.content = "<b>DKIM</b>: DKIM Record works properly";
        }

        // *** Return path situation ***
        if (return_path_status != true) {
            returnPathSituation.value = "failed";
            alertContents.path.situation = true;
            alertContents.path.type = "error";
            alertContents.path.content = `<b>Return Path Error</b>: ${return_path_error}`;
        } else {
            returnPathSituation.value = "success";
            alertContents.path.situation = true;
            alertContents.path.type = "success";
            alertContents.path.content = "<b>Return Path</b>: Return Path Record works properly";
        }

        // *** Redirection after check ***
        if (spfRecordSituation.value !== "failed" && dkimRecordSituation.value !== "failed") {
            if (props.parent === "warmup") {
                router.push({ name: "Dashboard/Warmup" });
            } else {
                router.push({ name: "Dashboard/Domain-Verification" });
            }
        }
    }
});

// *** Verify domain mutate section ***
const verifyDomain = () => {
    verifyDomainMutate({
        domain_id: domainID.value
    });
};

// *** Computeds ***
// # DKIM record name #
const dkimRecordName = computed(() => {
    if (Object.keys(props.setupData).length > 0) {
        if (props.setupData.dkim_record_name) return props.setupData.dkim_record_name;
        else return dkim_record_name.value;
    } else return dkim_record_name.value;
});

// # Domain name by SPF #
const domainNameBySPF = computed(() => {
    if (Object.keys(props.setupData).length > 0) {
        const regex = /include:spf\.(.*) ~all$/gm;
        const domain = props.setupData.spf.split(regex)[1];

        if (typeof domain !== "undefined") return domain;
        return "";
    } else return "";
});

// *** Copy code content ***
const copyCode = (ev) => {
    navigator.clipboard.writeText(ev.target.previousElementSibling.innerText);
    ev.target.innerText = "Copied";

    setTimeout(() => {
        ev.target.innerText = "Copy";
    }, 5000);
};

// *** Do it later button ***
const doItLaterButtonClick = () => {
    router.push({ name: "Dashboard/Domains" });
};

// *** Mounted lifecycle ***
onMounted(() => {
    domainID.value = route.params.domainID ?? 0;
    domainName.value = route.query.domain ?? "example.com";
});
</script>

<template>
    <div class="upgrade-dns-setup">
        <Typography v-if="props.title.length > 0" variant="heading2" fontWeight="bolder" margin="8px 0 8px -9.6px">{{
            props.title
        }}</Typography>
        <Typography
            v-if="props.description.length > 0"
            variant="subtitle1"
            color="var(--third-text-color)"
            margin="4px 0 4px 0"
        >
            {{ props.description }}
            <router-link to="/" class="upgrade-dns-setup-link">Go To Inbox</router-link>
        </Typography>

        <div class="upgrade-dns-setup-spf-dkim-path-container">
            <div class="upgrade-dns-setup-spf-record">
                <Typography variant="heading6" color="var(--secondary-text-color)">SPF Record</Typography>
                <Typography variant="caption" margin="0.16px 0 0 0" align="justify" color="var(--third-text-color)">
                    You need to add a TXT record at the apex/root of your domain (@) with the following content. if your
                    already send mail from another service, your may just need to
                    <b>include:spf.{{ domainNameBySPF }}</b> to your existing record
                </Typography>

                <Transition name="fade" mode="out-in">
                    <Alert
                        v-if="alertContents.spf.situation"
                        :variant="alertContents.spf.type"
                        :content="alertContents.spf.content"
                        :outlined="true"
                    />
                </Transition>

                <div
                    class="upgrade-dns-setup-code-container"
                    :class="
                        spfRecordSituation === 'pending'
                            ? 'upgrade-dns-setup-pending'
                            : spfRecordSituation === 'success'
                            ? 'upgrade-dns-setup-success'
                            : 'upgrade-dns-setup-failed'
                    "
                >
                    <code class="upgrade-dns-setup-code" v-text="props.setupData.spf"></code>
                    <button class="upgrade-dns-setup-copy" @click="copyCode">Copy</button>
                </div>
            </div>

            <div class="upgrade-dns-setup-dkim-record">
                <Typography variant="heading6" color="var(--secondary-text-color)">DKIM Record</Typography>
                <Typography variant="caption" margin="0.16px 0 0 0" align="justify" color="var(--third-text-color)">
                    You need to add a new TXT record with the name
                    <b>{{ dkimRecordName }}</b> with the following content.
                </Typography>

                <Transition name="fade" mode="out-in">
                    <Alert
                        v-if="alertContents.dkim.situation"
                        :type="alertContents.dkim.type"
                        :content="alertContents.dkim.content"
                        :outlined="true"
                    />
                </Transition>

                <div
                    class="upgrade-dns-setup-code-container"
                    :class="
                        dkimRecordSituation === 'pending'
                            ? 'upgrade-dns-setup-pending'
                            : dkimRecordSituation === 'success'
                            ? 'upgrade-dns-setup-success'
                            : 'upgrade-dns-setup-failed'
                    "
                >
                    <code class="upgrade-dns-setup-code" v-text="props.setupData.dkim"></code>
                    <button class="upgrade-dns-setup-copy" @click="copyCode">Copy</button>
                </div>
            </div>

            <div class="upgrade-dns-setup-return-path">
                <Typography variant="heading6" color="var(--secondary-text-color)">Return Path</Typography>
                <Typography variant="caption" margin="0.16px 0 0 0" align="justify" color="var(--third-text-color)">
                    This is optional but we recommend adding this to improve deliverability. You should add a
                    <strong>CNAME</strong> record at <b>mtaa.{{ domainName }}</b> to point to the hostname below.
                </Typography>

                <Transition name="fade" mode="out-in">
                    <Alert
                        v-if="alertContents.path.situation"
                        :type="alertContents.path.type"
                        :content="alertContents.path.content"
                        :outlined="true"
                    />
                </Transition>

                <div
                    class="upgrade-dns-setup-code-container"
                    :class="
                        returnPathSituation === 'pending'
                            ? 'upgrade-dns-setup-pending'
                            : returnPathSituation === 'success'
                            ? 'upgrade-dns-setup-success'
                            : 'upgrade-dns-setup-failed'
                    "
                >
                    <code class="upgrade-dns-setup-code" v-text="props.setupData.returnPath"></code>
                    <button class="upgrade-dns-setup-copy" @click="copyCode">Copy</button>
                </div>
            </div>

            <div class="upgrade-dns-setup-buttons-container">
                <Button hoverType="opacity" margin="8px 8px 0 0" :loading="verifyDomainIsLoading" @click="verifyDomain"
                    >Verify DNS</Button
                >
                <Button
                    hoverType="opacity"
                    margin="8px 0 0 0"
                    :outlined="true"
                    :loading="verifyDomainIsLoading"
                    @click="doItLaterButtonClick"
                    >Do it later</Button
                >
            </div>
        </div>
    </div>
</template>
