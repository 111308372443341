// *** Axios package ***
import axios from "axios";

// *** Toast package ***
import { toast } from "vue3-toastify/dist/index";

// *** Vue router ***
import router from "@/router/routes";
import { clearStorage, getTokenStorage, setTokenStorage } from "@/utility/storage/storageUtility";

// *** Authentication API ***
import { refreshTokenFn } from "../auth/mutations/authAPIMutationsFn";

// *** Axios instance ***
const dashboardAPI = axios.create({
    baseURL: `${process.env.VUE_APP_API_ADDRESS}`,
    headers: {
        "Content-Type": "application/json"
    }
});

// *** Interceptor ***
dashboardAPI.interceptors.request.use(
    (config) => {
        // *** Fetch tokens ***
        const fetchTokens = JSON.parse(window.localStorage.getItem("tokens"));

        // *** Change authorization header ***
        config.headers.Authorization = `Token ${fetchTokens.access}`;

        return config;
    },
    (error) => {
        if ([401, 403].includes(error.response.status)) {
            // *** Clear Storage ***
            window.localStorage.clear();

            // *** Redirect to login page ***
            router.push({ name: "Auth/Signin" });
        }

        return Promise.reject(error);
    }
);

// *** Response interceptor ***
dashboardAPI.interceptors.response.use(null, async (error) => {
    if (error.response.data && error.response.data.code && error.response.data.code === "token_not_valid") {
        // *** Fetch tokens ***
        const tokens = JSON.parse(window.localStorage.getItem("tokens"));

        if (tokens.refresh !== null) {
            try {
                const refreshToken = await refreshTokenFn({ refresh: tokens.refresh });

                setTokenStorage(refreshToken.data ? refreshToken.data.access : refreshToken.access, null);

                toast.info("You can refresh your page to update the information.");
            } catch (error) {
                clearStorage();

                toast.error("Token is expired.");

                // *** Redirect to signin page ***
                router.push({ name: "Auth/Signin" });
            }
        } else {
            // *** Clear storage 👉 remove all localstorage items like tokens and verify section after logout mutatoin completed ***
            clearStorage();

            toast.error("Token is expired.");

            // *** Redirect to signin page ***
            router.push({ name: "Auth/Signin" });
        }
    } else {
        // *** Get response error message ***
        const responseMessages = error.response.data.message.message;

        if (!error.response.data.status) {
            if (responseMessages.detail) {
                toast.error(responseMessages.detail);
            } else {
                for (const [_, value] of Object.entries(responseMessages)) {
                    value.forEach(() => {
                        toast.error(value);
                    });
                }
            }
        } else if (!responseMessages) {
            toast.error("Something went wrong. Please try again later.");
        } else {
            responseMessages.forEach((message) => {
                toast.error(message);
            });
        }
    }

    return Promise.reject(error);
});

export default dashboardAPI;
