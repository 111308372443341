<script setup>
// *** Vue ***
import { defineProps } from "vue";

// *** Props ***
const props = defineProps({
    manage: {
        type: Object,
        required: true
    }
});
</script>

<template>
    <div class="dashboard-domain-management-send-and-receive">
        <div class="dashboard-domain-management-send-and-receive-items">
            <div
                class="dashboard-domain-management-send-and-receive-item dashboard-domain-management-send-and-receive-processed-item"
            >
                <Icon name="send" width="28px" height="28px" color="var(--white-color)" margin="0 auto 8px" />
                <div class="dashboard-domain-management-send-and-receive-item-texts">
                    <Typography
                        fontWeight="bold"
                        variant="subtitle1"
                        color="var(--darken-white-color-1)"
                        align="center"
                        margin="0 0 -9.6px 0"
                        >Processed</Typography
                    >
                    <Typography
                        variant="subtitle1"
                        color="var(--darken-white-color-1)"
                        align="center"
                        margin="8px 0 0"
                        >{{ props.manage.held }}</Typography
                    >
                </div>
            </div>
            <div
                class="dashboard-domain-management-send-and-receive-item dashboard-domain-management-send-and-receive-delivered-item"
            >
                <Icon name="inbox" width="28px" height="28px" color="var(--white-color)" margin="0 auto 8px" />
                <div class="dashboard-domain-management-send-and-receive-item-texts">
                    <Typography
                        fontWeight="bold"
                        variant="subtitle1"
                        color="var(--darken-white-color-1)"
                        align="center"
                        margin="0 0 -9.6px 0"
                        >Delivered</Typography
                    >
                    <Typography
                        variant="subtitle1"
                        color="var(--darken-white-color-1)"
                        align="center"
                        margin="8px 0 0"
                        >{{ props.manage.outgoing }}</Typography
                    >
                </div>
            </div>
            <div
                class="dashboard-domain-management-send-and-receive-item dashboard-domain-management-send-and-receive-rejected-item"
            >
                <Icon name="baulk" width="28px" height="28px" color="var(--white-color)" margin="0 auto 8px" />
                <div class="dashboard-domain-management-send-and-receive-item-texts">
                    <Typography
                        fontWeight="bold"
                        variant="subtitle1"
                        color="var(--darken-white-color-1)"
                        align="center"
                        margin="0 0 -9.6px 0"
                        >Rejected</Typography
                    >
                    <Typography
                        variant="subtitle1"
                        color="var(--darken-white-color-1)"
                        align="center"
                        margin="8px 0 0"
                        >{{ props.manage.bounces }}</Typography
                    >
                </div>
            </div>
            <div
                class="dashboard-domain-management-send-and-receive-item dashboard-domain-management-send-and-rceive-received-item"
            >
                <Icon
                    name="envelopeOpenText"
                    width="28px"
                    height="28px"
                    color="var(--white-color)"
                    margin="0 auto 8px"
                />
                <div class="dashboard-domain-management-send-and-receive-item-texts">
                    <Typography
                        fontWeight="bold"
                        variant="subtitle1"
                        color="var(--darken-white-color-1)"
                        align="center"
                        margin="0 0 -9.6px 0"
                        >Received</Typography
                    >
                    <Typography
                        variant="subtitle1"
                        color="var(--darken-white-color-1)"
                        align="center"
                        margin="8px 0 0"
                        >{{ props.manage.incoming }}</Typography
                    >
                </div>
            </div>
        </div>
    </div>
</template>

