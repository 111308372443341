<script setup>
// *** Vue ***
import { computed } from "vue";

// *** Vuex(store) ***
import { useStore } from "vuex";

// *** Vue router ***
import { useRoute, RouterView } from "vue-router";

// *** Mixin ***
import { useGeneralMixin } from "@/mixin/general/useGeneralMixin";

// *** Components ***
import LeftSidebar from "@/components/Utility/LeftSidebar/LeftSidebarUtilityComponent.vue";
import WaveImage from "@/components/UI/Utility/Wave/WaveUIComponent.vue";
import Loader from "@/components/UI/Utility/Loader/LoaderUIComponent.vue";

// *** General mixin ***
const { screenWidth } = useGeneralMixin();

// *** Store instance ***
const store = useStore();

// *** Route instance ***
const route = useRoute();

// *** Route name computed ***
const routeName = computed(() => route.name);

// *** Left sidebar situation computed ***
const leftSidebarSituation = computed(() => store.state.general.leftSidebarSituation);

// *** Toggle loader state ***
const toggleLoaderState = computed(() => store.state.general.toggleLoader);

// *** Layout custom Style ***
const layoutCustomStyle = computed(() => {
    if (leftSidebarSituation.value && screenWidth.value >= 1200) {
        return {
            margin: `36.8px 46.4px 36.8px 300px`,
            width: "calc(100% - 300px - 46.4px)"
        };
    } else if (leftSidebarSituation.value == false && screenWidth.value >= 1200) {
        return {
            margin: `36.8px 46.4px 36.8px 182px`,
            width: "calc(100% - 182px - 46.4px)"
        };
    } else {
        return {
            margin: "36.8px 46.4px 36.8px 46.4px",
            width: "calc(100% - 92.8px)"
        };
    }
});

// *** Layout styles ***
const layoutStyles = computed(() => ({ ...layoutCustomStyle.value }));
</script>

<template>
    <div class="container">
        <div class="main-container" v-if="routeName && routeName.includes('Dashboard') == false">
            <RouterView v-slot="{ Component }">
                <Transition name="fade" mode="out-in">
                    <component :is="Component" />
                </Transition>
            </RouterView>
        </div>
        <div class="dashboard-container" v-else>
            <div class="dashboard-content-container">
                <LeftSidebar />
                <RouterView v-slot="{ Component }">
                    <div class="dashboard-content" :style="layoutStyles">
                        <Transition name="fade" mode="out-in">
                            <component :is="Component" />
                        </Transition>
                    </div>
                </RouterView>
            </div>
            <Wave-Image />
        </div>
        <Loader :isActive="toggleLoaderState.isActive" :text="toggleLoaderState.text" />
    </div>
</template>

