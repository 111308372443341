<script setup>
// *** Vue ***
import { ref, reactive } from "vue";

// *** Tanstack vue query ***
import { useMutation } from "@tanstack/vue-query";

// *** Toastify ***
import { toast } from "vue3-toastify/dist/index";

// *** mutation funciton(callback) ***
import { changePasswordFn } from "@/api/dashbaord/mutations/dashboardAPIMutationsFn.js";

// *** Ref states ***
const oldPassword = ref("");
const newPassword = ref("");
const confirmPassword = ref("");

// *** Reactive states ***
const errorToggleHandling = reactive({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false
});

const errorTextHandling = reactive({
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
});

// *** Change password mutation ***
const { isLoading: changePasswordIsLoading, mutate: changePasswordMutate } = useMutation({
    mutationKey: ["change-password-mutation"],
    mutationFn: changePasswordFn,
    onSuccess() {
        toast.success("Your password has been successfully changed.");
        oldPassword.value = "";
        newPassword.value = "";
        confirmPassword.value = "";
    }
});

// *** Change password ***
const changePassword = () => {
    errorToggleHandling.oldPassword = false;
    errorToggleHandling.newPassword = false;
    errorToggleHandling.confirmPassword = false;

    if (oldPassword.value.length < 8) {
        errorToggleHandling.oldPassword = true;
        errorTextHandling.oldPassword = "The old password must be at least 8 characters long.";
    }

    if (newPassword.value.length < 8) {
        errorToggleHandling.newPassword = true;
        errorTextHandling.newPassword = "The new password must be at least 8 characters long.";
    } else if (newPassword.value === oldPassword.value) {
        errorToggleHandling.newPassword = true;
        errorTextHandling.newPassword = "The new password must not be the same as the old password.";
    }

    if (confirmPassword.value.length < 8) {
        errorToggleHandling.confirmPassword = true;
        errorTextHandling.confirmPassword = "The confirm password must be at least 8 characters long.";
    } else if (newPassword.value !== confirmPassword.value) {
        errorToggleHandling.confirmPassword = true;
        errorTextHandling.confirmPassword = "Password confirmation is not the same as a new password";
    }

    if (Object.values(errorToggleHandling).every((val) => val === false)) {
        changePasswordMutate({
            old_password: oldPassword.value,
            new_password: newPassword.value
        });
    }
};
</script>

<template>
    <div class="dashboard-profile-reset-password-box">
        <div class="dashboard-profile-box-header">
            <Typography variant="subtitle2" color="var(--white-color)">Reset my password</Typography>
        </div>
        <form @submit.prevent="changePassword" class="dashboard-profile-reset-password-box-form">
            <Input
                type="password"
                id="old-password-profile-input"
                labelId="old-password-profile-label"
                labelText="Old password"
                :hasError="errorToggleHandling.oldPassword"
                :errorText="errorTextHandling.oldPassword"
                v-model="oldPassword"
            />

            <Input
                type="password"
                id="new-password-profile-input"
                labelId="new-password-profile-label"
                labelText="New password"
                :hasError="errorToggleHandling.newPassword"
                :errorText="errorTextHandling.newPassword"
                v-model="newPassword"
            />

            <Input
                type="password"
                id="confirm-password-profile-input"
                labelId="confirm-password-profile-label"
                labelText="Confirm password"
                :hasError="errorToggleHandling.confirmPassword"
                :errorText="errorTextHandling.confirmPassword"
                v-model="confirmPassword"
            />

            <Button type="submit" :fullWidth="true" margin="11px 0 8px" :loading="changePasswordIsLoading"
                >Change</Button
            >
        </form>
    </div>
</template>
