// *** Axios package ***
import axios from "axios";

// *** Toast package ***
import { toast } from "vue3-toastify/dist/index";

// *** Axios instance ***
const authAPI = axios.create({
    baseURL: `${process.env.VUE_APP_API_ADDRESS}/`,
    headers: {
        "Content-Type": "application/json"
    }
});

// *** Response interceptor ***
authAPI.interceptors.response.use(null, (error) => {
    // *** Get response error message ***
    const responseMessages = !error.response.data.status ? error.response.data : error.response.data.message.message;

    if (!error.response.data.status) {
        if (responseMessages.detail) {
            toast.error(responseMessages.detail);
        } else if (responseMessages.message) {
            if (typeof responseMessages.message === "object") {
                responseMessages.message.message.forEach((message) => toast.error(message));
            } else {
                toast.error(responseMessages.message.message);
            }
        } else {
            for (const [_, value] of Object.entries(responseMessages)) {
                value.forEach(() => {
                    toast.error(value);
                });
            }
        }
    } else if (!responseMessages) {
        toast.error("Something went wrong. Please try again later.");
    } else {
        responseMessages.forEach((message) => {
            toast.error(message);
        });
    }

    return Promise.reject(error);
});

export default authAPI;
