// *** Constants ***
import { TOKENS_STORAGE, USER_INFO_STORAGE, VERIFY_STORAGE } from "@/constants/storageConstant";

// *** Set token information in localstorage  ***
const setTokenStorage = (access, refresh) => {
    window.localStorage.setItem(
        TOKENS_STORAGE,
        JSON.stringify({
            access,
            refresh
        })
    );
};

// *** Set user informataion storage in localstorage ***
const setUserInfoStorage = (name, username, email) => {
    window.localStorage.setItem(
        USER_INFO_STORAGE,
        JSON.stringify({
            name,
            username,
            email
        })
    );
};

// *** Set verify storage in localstorage ***
const setVerifyStorage = (isUserVerify) => {
    window.localStorage.setItem(VERIFY_STORAGE, isUserVerify);
};

// *** Get toekn information form localstorage ***
const getTokenStorage = () => {
    return JSON.parse(window.localStorage.getItem(TOKENS_STORAGE));
};

// *** Get user information form localstorage ***
const getUserInformationStorage = () => {
    return JSON.parse(window.localStorage.getItem(USER_INFO_STORAGE));
};

// *** Get verify storage ***
const getVerifyStorage = () => {
    return JSON.parse(window.localStorage.getItem(VERIFY_STORAGE));
};

// *** Clear stroages ***
const clearStorage = () => {
    window.localStorage.clear();
};

// *** Export stroages ***
export {
    setTokenStorage,
    setUserInfoStorage,
    setVerifyStorage,
    getTokenStorage,
    getUserInformationStorage,
    getVerifyStorage,
    clearStorage
};
