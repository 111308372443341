<script setup>
// *** Vue ***
import { ref, reactive, watch, defineProps } from "vue";

// *** Components ***
import Deliverability from "./Deliverability/DashboardDeliverability.vue";
import Queue from "./Queue/DashboardQueue.vue";

// *** Ref states ***
const verifiedDomainName = ref(null);
const verifiedDomainID = ref(null);

// *** Reactive state ***
let domainList = reactive([]);
let verifiedDomain = reactive({});
let dropdownOptions = reactive([]);

// *** Props ***
const props = defineProps({
    domainListData: {
        type: Object,
        required: true
    }
});

// *** Handle deliverability update ***
const handleDeliveribilityUpdate = (domain) => {
    verifiedDomain = Object.assign(
        verifiedDomain,
        domainList.find((list) => list.domain === domain)
    );
    verifiedDomainName.value = verifiedDomain.domain;
    verifiedDomainID.value = verifiedDomain.id;
};

// *** Watch domain list data ***
watch(
    () => props.domainListData,
    (newList) => {
        domainList = Object.assign(domainList, newList.results);
        if (!!newList && domainList.length > 0) {
            dropdownOptions = Object.assign(
                dropdownOptions,
                domainList.map((val) => val.domain)
            );
            verifiedDomain = Object.assign(
                verifiedDomain,
                domainList.find((list) => list.dnsStatus && list.domainStatus)
            );
            if (verifiedDomain === undefined) {
                verifiedDomain = Object.assign(domainList[0]);
                verifiedDomainName.value = null;
                verifiedDomainID.value = null;
            } else {
                verifiedDomainName.value = verifiedDomain.domain;
                verifiedDomainID.value = verifiedDomain.id;
            }
        }
    }
);
</script>

<template>
    <div class="dashboard-main-deliverability-and-queue">
        <Deliverability :domainID="verifiedDomainID" :domainName="verifiedDomainName" />
        <Queue
            :domainID="verifiedDomainID"
            :domainName="verifiedDomainName"
            :dropdownOptions="dropdownOptions"
            @update="handleDeliveribilityUpdate"
        />
    </div>
</template>
