<script setup>
// *** Router ***
import { useRouter, useRoute } from "vue-router";

// *** Components ***
import DomainsList from "./List/DoaminListComponent.vue";
import CreateDomainModal from "./CreateModal/CreateDomainModalComponent.vue";

// *** Router instance ***
const router = useRouter();

// *** Route intsance ***
const route = useRoute();

// *** Toggle domain modal 👉 reverse current value ***
const openCreateDomainModal = () => {
    router.push({
        query: {
            ...route.query,
            modal: "createDomainModal"
        }
    });
};
</script>

<template>
    <div class="dashboard-domains-component-container">
        <DashboardHeader
            title="Domains"
            subtitle="Domains List"
            description="Emails will be sent to your recipients from the verified domains"
            imageSrc="/images/views/dashboard/domains.svg"
            imageAlternative="Domains image"
        >
            <template v-slot:content>
                <Button :radius="3" @click="openCreateDomainModal">Add Domain</Button>
            </template>
        </DashboardHeader>
        <DomainsList />
        <CreateDomainModal :domainModal="domainModal" />
    </div>
</template>
