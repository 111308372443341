// *** Vue and Plugins ***
import { defineAsyncComponent } from "vue";

const globalComponents = {
    // *** Components ***
    Tooltip: defineAsyncComponent(() => import("./Tooltip/TooltipUIComponent.vue")),
    Input: defineAsyncComponent(() => import("./Input/InputUIComponent.vue")),
    Checkbox: defineAsyncComponent(() => import("./Checkbox/CheckboxUIComponent.vue")),
    Textarea: defineAsyncComponent(() => import("./Textarea/TextareaUIComponent.vue")),
    Selectbox: defineAsyncComponent(() => import("./Selectbox/SelectboxUIComponent.vue")),
    Alert: defineAsyncComponent(() => import("./Alert/AlertUIComponent.vue")),
    Chip: defineAsyncComponent(() => import("./Chip/ChipUIComponent.vue")),
    Modal: defineAsyncComponent(() => import("./Modal/ModalUIComponent.vue")),
    Button: defineAsyncComponent(() => import("./Button/ButtonUIComponent.vue")),
    Icon: defineAsyncComponent(() => import("./Icon/IconUIComponent.vue")),
    Divider: defineAsyncComponent(() => import("./Divider/DividerUIComponent.vue")),
    Table: defineAsyncComponent(() => import("./Table/TableUIComponent.vue")),
    Typography: defineAsyncComponent(() => import("./Typography/TypographyUIComoponent.vue")),
    Switch: defineAsyncComponent(() => import("./Switch/SwitchUIComponent.vue")),
    Image: defineAsyncComponent(() => import("./Image/ImageUIComponent.vue")),
    Pagination: defineAsyncComponent(() => import("./Pagination/PaginationUIComponent.vue")),
    Dropdown: defineAsyncComponent(() => import("./Dropdown/DropdownUIComponent.vue")),
    SweetAlert: defineAsyncComponent(() => import("./SweetAlert/SweetAlertUIComponent.vue")),
    ArrowPagination: defineAsyncComponent(() => import("./ArrowPagination/ArrowPaginationUIComponent.vue")),
    Loading: defineAsyncComponent(() => import("./Loading/LoadingUIComponent.vue")),

    // *** Containers ***
    DashboardContainer: defineAsyncComponent(() => import("@/components/Containers/Dashboard/DashboardContainer.vue")),

    // *** Another ***
    DashboardHeader: defineAsyncComponent(() => import("@/components/Utility/Header/HeaderUtilityComponent.vue")),

    // *** Third party packages ***
    apexchart: defineAsyncComponent(() => import("vue3-apexcharts"))
};

const RegisterComponents = (app) => {
    Object.entries(globalComponents).forEach(([name, component]) => {
        app.component(name, component);
    });
    return app;
};

export default RegisterComponents;
