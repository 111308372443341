export default {
    // *** Before component mount ***
    beforeMount(el, binding) {
        // *** Define scroll event function and return value or call main function ***
        el.scrollEvent = () => {
            binding.value();
        };

        // *** Define scroll event with scrollEvent callback ***
        window.addEventListener("scroll", el.scrollEvent);
    },

    // *** When Component unmounted ***
    unmounted(el) {
        // *** Remove scroll event ***
        window.removeEventListener("scroll", el.scrollEvent);
    }
};

