<script setup>
// *** Vue router ***
import { useRouter } from "vue-router";

// *** Tanstack vue query ***
import { useQuery } from "@tanstack/vue-query";

// *** Query functions(callback) ***
import { domainListFn } from "@/api/dashbaord/queries/dashboardAPIQueriesFn";

// *** Components ***
import StartSection from "./Start/DashboardStartComponent.vue";
import SummaryData from "./SummaryData/DashboardSummaryData.vue";
import { ref } from "vue";

// *** Router instance ***
const router = useRouter();

// *** Ref states ***
const domainListData = ref([]);

// *** Domain list query ***
useQuery({
    queryKey: ["domain-list-query"],
    queryFn: () => domainListFn({ page: 1 }),
    onSuccess(data) {
        domainListData.value = data;
    }
});

// *** Setup click button ***
const setupClick = () => router.push({ name: "Dashboard/Domains" });
</script>

<template>
    <div class="dashboard-main-component-container">
        <DashboardHeader
            title="Dashboard"
            subtitle="Setup your smtp"
            description=""
            imageSrc="/images/views/dashboard/letter.svg"
            imageAlternative="Setup SMTP Image"
        >
            <template v-slot:content>
                <Button :radius="3" margin="16px 0 12px" @click="setupClick">Setup</Button>
            </template>
        </DashboardHeader>

        <StartSection v-if="domainListData && domainListData.count != null && !domainListData.count" />

        <!-- :domains="domainListData ? domainListData.results : []" -->
        <SummaryData :domainListData="domainListData" />
    </div>
</template>
