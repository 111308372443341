// *** MIDDLEWARES ***
import MutationChangeState from "@/utility/mutationChangeState/mutationChangeStateUtility";

export default {
    namespaced: true,
    state: {
        leftSidebarSituation: false,
        isDisabledLeftSidebarLinks: false,
        toggleLoader: {
            isActive: false,
            text: "Fetching ..."
        },
        screenWidth: window.innerWidth,
        commitName: "general/generalChangeState"
    },
    mutations: {
        generalChangeState(state, payload) {
            MutationChangeState(state, payload);
        }
    }
};

