<script setup>
// *** Vue ***
import { ref, reactive } from "vue";

// *** Router ***
import { useRouter } from "vue-router";

// *** Tanstack vue query ***
import { useMutation } from "@tanstack/vue-query";

// *** Utils ***
import { domainRegex } from "@/utility/regex/regexUtility";

// *** Vue toastify ***
import { toast } from "vue3-toastify/dist/index";

// *** Mutation functions(callback) ***
import { createNewDomainFn } from "@/api/dashbaord/mutations/dashboardAPIMutationsFn";

// *** Router instance ***
const router = useRouter();

// *** Ref states ***
const domain = ref("");

// *** Reactive state ***
const errorToggleHandling = reactive({
    domain: false
});
const errorTextHandling = reactive({
    domain: ""
});

// *** Create new domain mutation ***
const { isLoading: createNewDomainIsLoading, mutate: createNewDomainMutate } = useMutation({
    mutationKey: [],
    mutationFn: createNewDomainFn,
    onSuccess(data) {
        toast.success("A new domain was created.");
        domain.value = "";
        router.replace(`domain-verification/${data.id}/?domain=${data.domain}`);
    }
});

// *** Close domain modal ***
const closeDomainModal = () => {
    domain.value = "";
    errorToggleHandling.domain = false;
    router.back();
};

// *** Create new domain ***
const domainButtonClickEvent = () => {
    errorToggleHandling.domain = false;
    if (domain.value.includes("www.") || !domainRegex.test(domain.value)) {
        errorToggleHandling.domain = true;
        errorTextHandling.domain = "Your domain is incorrect.";
    } else {
        errorToggleHandling.domain = false;
        createNewDomainMutate({
            domain: domain.value
        });
    }
};
</script>

<template>
    <Modal type="success" title="Add Domain" @close="closeDomainModal" modalID="createDomainModal">
        <template v-slot:content>
            <form @submit.prevent="domainButtonClickEvent">
                <Input
                    type="text"
                    id="domain-input"
                    labelId="domain-name-label"
                    labelText="Domain Name"
                    :hasError="errorToggleHandling.domain"
                    :errorText="errorTextHandling.domain"
                    v-model="domain"
                />
                <div class="modal-global-footer">
                    <Button hoverType="opacity" type="submit" :loading="createNewDomainIsLoading">Create</Button>
                    <Button
                        :outlined="true"
                        hoverType="opacity"
                        type="button"
                        :loading="createNewDomainIsLoading"
                        @click="closeDomainModal"
                        >Close</Button
                    >
                </div>
            </form>
        </template>
    </Modal>
</template>
