// *** Vue and plugins ***
import { createApp } from "vue";

// *** Vue query plugin ***
import { VueQueryPlugin } from "@tanstack/vue-query";

// *** Vue toastify ***
import Vue3Toastify from "vue3-toastify";

// *** Router ***
import router from "./router/routes";

// *** Store ***
import store from "./store/store";

// *** Ui components registration ***
import UiComponentsRegistration from "@/components/UI/Global/uiComponents.js";

// *** Directives registration ***
import DirectivesRegistration from "./directives/directives.js";

// *** Plugins ***
import queryClient from "@/plugins/queryClient/queryClientPlugin";
import toastifyPluginOptions from "@/plugins/toastify/toastifyPlugin";

// *** Third party packages ***
import Axios from "axios";

// *** Main component ***
import App from "./App.vue";

// *** Lottie player ***
import "@lottiefiles/lottie-player";

// *** Assets files ***
import "./assets/styles/app.css";

// *** Create app ***
const vueInstance = createApp(App);

// *** UI components registration ***
const uiComponentsRegistration = UiComponentsRegistration(vueInstance);

// *** Directives registration ***
const directivesRegistration = DirectivesRegistration(uiComponentsRegistration);

// *** Application ***
const app = directivesRegistration;

// *** Global properties ***
app.config.globalProperties.$http = Axios;

// *** Use plugin ***
// # Vuex #
app.use(store);

// # Vue router #
app.use(router);

// # Tanstack query #
app.use(VueQueryPlugin, { queryClient });

// # Toastify #
app.use(Vue3Toastify, toastifyPluginOptions);

// *** Mount in div with app id ***
app.mount("#app");
