<script setup>
// *** Vue ***
import { defineProps } from "vue";

// *** Props ***
const props = defineProps({
    isActive: {
        type: Boolean,
        required: true
    },
    text: {
        type: String,
        default: "Loading ..."
    }
});
</script>

<template>
    <Transition name="fade" mode="out-in">
        <div class="loader-global-container" v-if="props.isActive">
            <Image src="assets/images/logos/mailerever.svg" alt="Loader" width="200px" height="200px" />
            <Typography variant="heading1" fontWeight="normal" margin="16px 0 0" color="var(--main-color)">{{
                props.text
            }}</Typography>
        </div>
    </Transition>
</template>

