// *** Dashboard views ***
import Dahsboard from "@/views/Dashboard/Index/DashboardView.vue";
import Domains from "@/views/Dashboard/Domains/DomainsView.vue";
import Support from "@/views/Dashboard/Support/SupportView.vue";
import Profile from "@/views/Dashboard/Profile/ProfileView.vue";
import DomainVerification from "@/views/Dashboard/DomainVerification/DomainVerificationView.vue";
import DomainManagement from "@/views/Dashboard/DomainManagement/DomainManagementView.vue";
import ColdEmail from "@/views/Dashboard/ColdEmail/ColdEmailView.vue";
// import IncomingEmails from "@/views/Dashboard/IncomingEmails/index/IncomingEmailsView.vue";
// import IncomingEmailsManagement from "@/views/Dashboard/IncomingEmails/management/incomingEmailsManagementView.vue";
// import IncomingEmailsCreate from "@/views/Dashboard/IncomingEmails/create/incomingEmailsCreateView.vue";

// *** Dashboard routes ***
// # path 👉 url path 👉 loclahost:3000/
// # name 👉 route name for redirection 👉 router.push({ name: "Dashboard/Main" })
// # component 👉 when we go localhsot:3000/ execute this component ***
const dashboardRoutes = [
    {
        path: "/",
        name: "Dashboard/Main",
        component: Dahsboard
    },
    {
        path: "/domains",
        name: "Dashboard/Domains",
        component: Domains
    },
    // {
    //     path: "/incoming-emails",
    //     children: [
    //         {
    //             path: "",
    //             name: "Dashboard/Incoming-Emails",
    //             component: IncomingEmails
    //         },
    //         {
    //             path: ":domainID",
    //             name: "Dashboard/Incoming-Emails-Management",
    //             component: IncomingEmailsManagement
    //         },
    //         {
    //             path: "create/:domainID",
    //             name: "Dashboard/Incoming-Email-Create",
    //             component: IncomingEmailsCreate
    //         }
    //     ]
    // },
    {
        path: "/support",
        name: "Dashboard/Support",
        component: Support
    },
    {
        path: "/profile",
        name: "Dashboard/Profile",
        component: Profile
    },
    {
        path: "/domain-verification/:domainID",
        name: "Dashboard/Domain-Verification",
        component: DomainVerification
    },
    {
        path: "/domain-management/:domainID",
        name: "Dashboard/Domain-Management",
        component: DomainManagement
    },
    {
        path: "/smtp-email-pricing",
        name: "Dashboard/SMTP-Email-Pricing",
        component: ColdEmail
    }
];

export default dashboardRoutes;
