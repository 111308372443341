<script setup>
// *** Vue ***
import { defineProps, ref } from "vue";

// *** Props ***
const props = defineProps({
    smtpData: {
        type: Object,
        required: true
    }
});

// *** Ref states ***
const smtpDataModels = ref(props.smtpData);
</script>

<template>
    <DashboardContainer class="dashboard-domain-management-smtp">
        <Typography variant="heading4" color="var(--secondary-text-color)">SMTP</Typography>

        <div class="dashboard-domain-management-smtp-inputs">
            <Input
                type="text"
                id="domain-smtp-server-input"
                labelId="domain-smtp-server-label"
                labelText="Server"
                width="calc(50% - 8px)"
                inputColor="var(--third-text-color)"
                :readonly="true"
                :isCopy="true"
                v-model="smtpDataModels.smtpHost"
            />

            <div class="dashboard-domain-management-smtp-port-input-and-text">
                <Input
                    type="text"
                    id="domain-smtp-port-input"
                    labelId="domain-smtp-port-label"
                    labelText="Port"
                    inputColor="var(--third-text-color)"
                    :readonly="true"
                    :isCopy="true"
                    v-model="smtpDataModels.smtpPort"
                />
                <Typography variant="body2" color="var(--main-color)" align="right" margin="4px 0 0 "
                    >Connection security: TLS</Typography
                >
            </div>

            <Input
                type="text"
                id="domain-smtp-username-input"
                labelId="domain-smtp-username-label"
                labelText="Username"
                width="calc(50% - 8px)"
                inputColor="var(--third-text-color)"
                :readonly="true"
                :isCopy="true"
                v-model="smtpDataModels.smtpUsername"
            />

            <div class="dashboard-domain-management-smtp-password-input-and-text">
                <Input
                    type="text"
                    id="domain-smtp-password"
                    labelId="domain-smtp-password-label"
                    labelText="Password"
                    inputColor="var(--third-text-color)"
                    :readonly="true"
                    :isCopy="true"
                    v-model="smtpDataModels.smtpToken"
                />

                <!-- <div class="dashboard-domain-management-smtp-button-container">
                    <button
                        class="dashboard-domain-management-smtp-button"
                        @click="toggleSMTPResetPasswordClickAndCloseEvent(true)"
                    >
                        Reset Password
                    </button>
                </div> -->
            </div>
        </div>

        <!-- 
        #TODO 👉 change this section after uncomment ***
        <Modal
            modalID="resetSMTPPasswordModal"
            :customFooter="false"
            type="success"
            title="Reset password"
            modalColor="var(--main-color)"
        >
            <template v-slot:content>
                <form @submit.prevent="resetPassword">
                    <Input
                        type="password"
                        id="domain-smtp-new-password"
                        labelId="domain-smtp-new-password-label"
                        labelText="New Password"
                        :hasError="errorToggleHandling.password"
                        :errorText="errorTextHandling.password"
                        v-model=""
                    />

                    <Input
                        type="password"
                        id="domain-smtp-repeat-password"
                        labelId="change-repeat-password-label"
                        labelText="Repeat password"
                        :hasError="errorToggleHandling.repeat_password"
                        :errorText="errorTextHandling.repeat_password"
                        v-model=""
                    />

                    <div class="modal-global-footer">
                        <Button type="submit" hoverType="opacity" margin="8px 12px 8px 0">Reset</Button>
                        <Button
                            :outlined="true"
                            hoverType="opacity"
                            @click="toggleSMTPResetPasswordClickAndCloseEvent(false)"
                            >Close</Button
                        >
                    </div>
                </form>
            </template>
        </Modal> -->
    </DashboardContainer>
</template>
