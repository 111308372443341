// *** Get dashboard api instance ***
import dashboardAPI from "../dashboardAPI";

// *** Domain credential query function(callback) ***
const domainCredentialFn = async (data) => {
    // *** Get domain credential ***
    const domainCredentialResponse = await dashboardAPI.get("credenttials/", {
        params: {
            domain_id: data.domain_id
        }
    });

    // *** Return domain credential response data ***
    return domainCredentialResponse.data;
};

// *** Domain activity query function (callback) ***
const domainActivityFn = async (data) => {
    // *** API Path ***
    const apiPath =
        data.searchBy !== "all" && data.search.length > 0 && data.searchBy.length > 0
            ? `activity/?domain_id=${data.domainID}&page=${data.activePage}&searchBy=${data.searchBy}&search=${data.searchBy}`
            : `activity/?domain_id=${data.domainID}&page=${data.activePage}`;

    // *** Get domain activity ***
    const domainActivityResponse = await dashboardAPI.get(apiPath);

    // *** Return domain activity response data ***
    if (domainActivityResponse.data.message === null) {
        return [];
    } else {
        return domainActivityResponse.data.message;
    }
};

// *** Domain list query function(callback) ***
const domainListFn = async (data) => {
    // *** Domain list ***
    const domainListResponse = await dashboardAPI.get("index/", {
        params: data
    });

    // *** Return domain list response data ***
    return domainListResponse.data;
};

// *** Domain verification setup info query function(callback) ***
const domainVerificationSetupInfoFn = async (data) => {
    // *** Domain verification setup info ***
    const domainVerificationSetupInfoResponse = await dashboardAPI.get("setup/", {
        params: data
    });

    // *** Return domain verification setup info response data ***
    return domainVerificationSetupInfoResponse.data;
};

// *** Incoming email list query function(callback) ***
const incomingEmailListFn = async (data) => {
    // *** Incoming email list ***
    const incomingEmailListResponse = await dashboardAPI.get("incommingemail/", {
        params: data
    });

    // *** Return incoming email list response data ***
    return incomingEmailListResponse.data;
};

// *** Incoming user query function(callback) ***
const incomingUserFn = async (data) => {
    // *** Incoming user ***
    const incomingEmailResponse = await dashboardAPI.get("incomminguser/", {
        params: data
    });

    // *** Return incoming user response data ***
    return incomingEmailResponse.data;
};

// *** Incoming domain list query function(callback) ***
const incomingDomainListFn = async () => {
    // *** Incoming domain list ***
    const incomingDomainListResponse = await dashboardAPI.get("incommingdomain/");

    // *** Return incoming domain list response data ***
    return incomingDomainListResponse.data;
};

// *** Domain chart info by id query function(callback) ***
const domainChartInfoByIDFn = async (data) => {
    // *** Domain chart info by id ***
    const domainChartInfoByIDResponse = await dashboardAPI.get("filtermessagesbytime/", {
        params: data
    });

    // *** Return domain chart info by id response data ***
    const chartInfo = domainChartInfoByIDResponse.data.message;
    if (chartInfo !== null && chartInfo != "credential not find" && chartInfo.length > 0) {
        return chartInfo;
    }
    {
        return [];
    }
};

// *** Get MTA query function(callback) ***
const getMTAFn = async () => {
    // *** Get mta ***
    const getMTAResponse = await dashboardAPI.get("mta/");

    // *** Get mta response data ***
    return getMTAResponse.data;
};

// *** Profile information query function(callback) ***
const profileInformationFn = async () => {
    // *** Get profile information ***
    const profileInformationResponse = await dashboardAPI.get("profile/");

    // *** Get profile response data ***
    return profileInformationResponse.data;
};

// *** Payment list query function(callback) ***
const paymentListFn = async () => {
    // *** Payment list ***
    const paymentListResponse = await dashboardAPI.get("payment_list/");

    // *** Payment list response data ***
    return paymentListResponse.data;
};

// *** Domain manage domain query function(callback) ***
const domainManageListFn = async (data) => {
    // *** Domain manage list ***
    const domainManageListResponse = await dashboardAPI.get("manage/", {
        params: data
    });

    // *** Return domain manage list response data ***
    return domainManageListResponse.data;
};

export {
    domainCredentialFn,
    domainActivityFn,
    domainListFn,
    domainVerificationSetupInfoFn,
    incomingEmailListFn,
    incomingUserFn,
    incomingDomainListFn,
    domainChartInfoByIDFn,
    getMTAFn,
    profileInformationFn,
    paymentListFn,
    domainManageListFn
};
