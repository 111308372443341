// *** MIDDLEWARES ***
import MutationChangeState from "@/utility/mutationChangeState/mutationChangeStateUtility";

export default {
    namespaced: true,
    state: {
        addMTAModal: false,
        commitName: "dashboard/dashboardChangeState"
    },
    mutations: {
        dashboardChangeState(state, payload) {
            MutationChangeState(state, payload);
        }
    }
};

