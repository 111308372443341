<script setup>
// *** Vue ***
import { defineProps } from "vue";

// *** Props ***
const props = defineProps({
    bounceRate: {
        type: Number,
        required: true
    }
});
</script>

<template>
    <div class="dashboard-domain-management-reputation">
        <div class="dashboard-domain-management-reputation-wrapper">
            <svg class="dashboard-domain-management-reputation-meter">
                <circle class="dashboard-domain-management-reputation-meter-left" r="96" cx="135" cy="142"></circle>
                <circle class="dashboard-domain-management-reputation-meter-center" r="96" cx="136" cy="142"></circle>
                <circle class="dashboard-domain-management-reputation-meter-right" r="96" cx="138" cy="142"></circle>
                <polygon
                    class="dashboard-domain-management-reputation-meter-clock"
                    points="129,145 137,90 145,145"
                    :style="{ transform: `rotate(${-102 + (props.bounceRate <= 0 ? 0 : props.bounceRate) * 2}deg)` }"
                ></polygon>
                <circle class="dashboard-domain-management-reputation-meter-circle" r="10" cx="137" cy="145"></circle>
            </svg>
            <Typography variant="heading6" margin="32px 0 0" color="var(--main-color)" align="center" fontWeight="400"
                >Bounce Rate: <b>{{ props.bounceRate <= 0 ? 0 : props.bounceRate }} %</b></Typography
            >
        </div>
    </div>
</template>

