<script setup>
// *** Vue ***
import { ref, reactive } from "vue";

// *** Tanstack vue query ***
import { useMutation, useQuery } from "@tanstack/vue-query";

// *** Toastify ***
import { toast } from "vue3-toastify/dist/index";

// *** Mutation and Query funciton(callback) ***
import { profileInformationFn } from "@/api/dashbaord/queries/dashboardAPIQueriesFn";
import { updateProfileFn } from "@/api/dashbaord/mutations/dashboardAPIMutationsFn.js";

// *** Utils ***
import { emailRegex } from "@/utility/regex/regexUtility";
import { setUserInfoStorage, getUserInformationStorage } from "@/utility/storage/storageUtility";

//  *** Ref states ***
const firstName = ref("");
const email = ref("");

// *** Reactive states ***
const errorToggleHandling = reactive({
    firstName: false,
    email: false
});
const errorTextHandling = reactive({
    firstName: "",
    email: ""
});

// *** Get user information storage ***
const userInformationStorage = getUserInformationStorage();

// *** Profile infomration query ***
const {
    isLoading: profileIsLoading,
    isFetching: profileIsFetching,
    refetch: profileInformationRefetch
} = useQuery({
    queryKey: ["profile-information-query"],
    queryFn: () => profileInformationFn(),
    onSuccess(data) {
        firstName.value = data.data.first_name;
        email.value = data.data.email;
        setUserInfoStorage(data.data.firstName, userInformationStorage.username, data.data.email);
    }
});

// *** Update Profile mutation ***
const { isLoading: updateProfileIsLoading, mutate: updateProfileMutate } = useMutation({
    mutationKey: ["profile-update-mutation"],
    mutationFn: updateProfileFn,
    onSuccess() {
        toast.success("Your information has been successfully changed.");
        profileInformationRefetch();
    }
});

// *** Profile update ***
const profileUpdate = () => {
    errorToggleHandling.firstName = false;
    errorToggleHandling.email = false;

    if (firstName.value.length < 3) {
        errorToggleHandling.firstName = true;
        errorTextHandling.firstName = "Your first name must be at least 3 characters long.";
    }

    if (emailRegex.test(email.value) === false) {
        errorToggleHandling.email = true;
        errorTextHandling.email = "Your email is not correct.";
    }

    if (Object.values(errorToggleHandling).every((val) => val === false)) {
        updateProfileMutate({
            first_name: firstName.value,
            email: email.value
        });
    }
};
</script>

<template>
    <div class="dashboard-profile-detail-box">
        <div class="dashboard-profile-box-header">
            <Typography variant="subtitle2" fontWeight="normal" color="var(--white-color)">My Detail</Typography>
        </div>
        <Loading
            v-if="profileIsLoading || profileIsFetching"
            lottieWidth="150px"
            lottieHeight="150px"
            margin="-60px auto 0"
        />
        <form v-else @submit.prevent="profileUpdate" class="dashboard-profile-detail-box-form">
            <Input
                type="text"
                id="name-profile-input"
                labelId="name-profile-label"
                labelText="Name"
                :hasError="errorToggleHandling.firstName"
                :errorText="errorTextHandling.firstName"
                v-model="firstName"
            />

            <Input
                type="email"
                id="email-address-profile-input"
                labelId="email-address-profile-label"
                labelText="Email Address"
                :hasError="errorToggleHandling.email"
                :errorText="errorTextHandling.email"
                margin="16px 0 12px"
                v-model="email"
            />

            <Button type="submit" :fullWidth="true" margin="11px 0 8px" :loading="updateProfileIsLoading"
                >Change</Button
            >
        </form>
    </div>
</template>
