<script setup>
// #TODO 👉 add loader

// *** Router ***
import { useRouter } from "vue-router";

// *** Tanstack vue query ***
import { useMutation } from "@tanstack/vue-query";

// *** Vue toastify ***
import { toast } from "vue3-toastify/dist/index";

// *** Mutation functions(callback) ***
import { verifyEmailFn } from "@/api/dashbaord/mutations/dashboardAPIMutationsFn";

// *** Storage utility ***
import { getUserInformationStorage } from "@/utility/storage/storageUtility";

// *** Mixin ***
import { useGeneralMixin } from "@/mixin/general/useGeneralMixin";

// *** Router instance ***
const router = useRouter();

// *** User information storage utility ***
const userInformation = getUserInformationStorage();

// *** Mixins ***
const { appHostName } = useGeneralMixin();

// *** Application name (extra data) ***
const appName = appHostName.value.includes("127.0.0.1")
    ? ""
    : String(appHostName.value[0]).toUpperCase() + String(appHostName.value[0]).slice(1);

// *** Verify email mutation ***
const { mutate: verifyEmailMutate } = useMutation({
    mutationKey: ["verify-email-query"],
    mutationFn: verifyEmailFn,
    onSuccess() {
        toast.success("Verify email has been sent to you.");
    }
});

// *** Handle resend email ***
const handleResendEmail = () => {
    verifyEmailMutate({
        email: userInformation.email
    });
};

// *** Redirect user to domain page ***
const goToDomainPage = () => router.push({ name: "Dashboard/Domains" });
</script>

<template>
    <div class="dashboard-main-start">
        <div class="dashboard-main-start-left-section">
            <Typography variant="heading5" color="var(--main-text-color)">Let's get you started</Typography>
            <Typography variant="subtitle1" color="var(--third-text-color)">
                Use the trial domain to discover what{{ appName }} can do for your and start sending!
            </Typography>
            <lottie-player
                autoplay
                loop
                preserveAspectRatio="xMidYMid slice"
                src="https://lottie.host/8ed37f73-3c69-4930-8eb3-d96390ea927e/Fssh5EGguU.json"
                class="lottie"
            ></lottie-player>
        </div>
        <div class="dashboard-main-start-right-section">
            <div
                class="dashboard-main-start-step-container verify-email-step"
                :class="userIsVerified && 'dashboard-main-start-step-active'"
            >
                <div class="step-number">
                    <template v-if="userIsVerified">
                        <svg class="check-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path
                                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                            />
                        </svg>
                    </template>
                    <template v-else>1</template>
                </div>
                <div class="title-and-subtitle">
                    <Typography variant="heading6" color="var(--main-text-color)">Verify your email address</Typography>
                    <Typography variant="subtitle2" color="var(--third-text-color)">
                        <span v-if="userIsVerified">Your email is verified 🥳🥳🥳</span>
                        <span v-else
                            >Check your inbox and click that verification link we sent your. Didn't reveice it ?
                            <span @click="handleResendEmail" class="resend-email-span">Resend email</span>.</span
                        >
                    </Typography>
                </div>
            </div>

            <div class="dashboard-main-start-step-container verify-email-step">
                <div class="step-number">2</div>
                <div class="title-and-subtitle">
                    <Typography variant="heading6" color="var(--main-text-color)">Create a domain</Typography>
                    <Typography variant="subtitle2" color="var(--third-text-color)">
                        Emails will be sent to your recipients from the verified domains
                    </Typography>
                    <Button margin="12px 0 0" :loading="createDomainIsLoading" @click="goToDomainPage"
                        >Create domain</Button
                    >
                </div>
            </div>
        </div>
    </div>
</template>
