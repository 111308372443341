<script setup>
// *** Vue ***
import { ref } from "vue";

// *** Route ***
import { useRoute } from "vue-router";

// *** Vue toastify ***
import { toast } from "vue3-toastify/dist/index";

// *** Tanstack vue query ***
import { useMutation } from "@tanstack/vue-query";

// *** Mutation functions(callback) ***
import { changeTrackFn } from "@/api/dashbaord/mutations/dashboardAPIMutationsFn";

// *** Route instance ***
const route = useRoute();

// *** Ref state ***
const inboxTracker = ref(route.query.inboxTracker);

// *** Change track mutation ***
const { isLoading: changeTrackIsLoading, mutate: changeTrackMutate } = useMutation({
    mutationKey: ["change-track-mutation"],
    mutationFn: changeTrackFn,
    onSuccess() {
        inboxTracker.value = !inboxTracker.value;
        toast.success(inboxTracker.value ? "Homa is Active 😍😍😍" : "Homa is deactive.");
    }
});

// *** Change track ***
const changeTrack = () => {
    changeTrackMutate({
        status: !inboxTracker.value,
        domain_id: Number(route.params.domainID)
    });
};
</script>

<template>
    <div class="dashboard-domain-management-inbox-tracker">
        <div class="left-section">
            <Typography variant="heading4" margin="0 0 24px 0" color="var(--secondary-text-color)"
                >Inbox Tracker</Typography
            >
            <Typography variant="heading5" fontWeight="normal" margin="0 0 8px 0" color="var(--third-text-color)"
                >Processing request🤔 ...</Typography
            >
            <Typography variant="body1" margin="0 0 16px 0" color="var(--third-text-color)"
                >Outcome will be communicated promptly upon completion. Homa, Your AI Assistant</Typography
            >
            <Button
                type="submit"
                :outlined="true"
                margin="8px 0 0 0"
                color="var(--main-text-color)"
                @click="changeTrack"
                :loading="changeTrackIsLoading"
            >
                {{ inboxTracker ? "Deactive Track" : "Active Track" }}</Button
            >
        </div>
        <div class="right-section">
            <lottie-player
                autoplay
                loop
                preserveAspectRatio="xMidYMid slice"
                src="https://lottie.host/87f06bcd-2a30-4ba1-bf11-a2a6b5aa5bf7/74HQVrhKs3.json"
                class="lottie"
            ></lottie-player>
        </div>
    </div>
</template>
