// *** Error views ***
import Error404 from "@/views/Errors/404/Error404View.vue";

// *** Error routes ***
// # path 👉 url path 👉 loclahost:3000/fjkdsjfioew 👉 not found
// # name 👉 route name for redirection 👉 router.push({ name: "NotFound" })
// # component 👉 when we go localhsot:3000/fjkdsjfioew execute this component ***
const errorRoutes = [
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: Error404
    }
];

export default errorRoutes;

