export default {
    // *** Before component mount ***
    beforeMount(el, binding) {
        // *** Create click escape button event in el object ***
        el.clickEscapeButton = (ev) => {
            // *** When keyboard click escape button return binding value or call main function ***
            if (ev.code === "Escape") {
                binding.value();
            }
        };

        // *** Create keydown event with clickEscapeButton callback ***
        window.addEventListener("keydown", el.clickEscapeButton);
    },

    // *** When Component unmounted ***
    unmounted(el) {
        // *** Remove keydown event ***
        window.removeEventListener("keydown", el.clickEscapeButton);
    }
};

