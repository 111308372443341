<script setup>
// *** Vue ***
import { ref } from "vue";

// *** Vue router ***
import { useRouter, useRoute } from "vue-router";

// *** Tanstack vue query ***
import { useQuery } from "@tanstack/vue-query";

// *** Query functions(callback) ***
import { domainVerificationSetupInfoFn } from "@/api/dashbaord/queries/dashboardAPIQueriesFn";

// *** Components ***
import DNSSetup from "@/components/Utility/DNSSetup/DNSSetupUtilityComponent.vue";

// *** Router instance ***
const router = useRouter();

// *** Route instance ***
const route = useRoute();

// *** Ref instance ***
const setupData = ref({
    id: 1,
    dkim_record_name: "example-api._domainkey",
    dkim: "v=DKIM1; t=s; h=sha256; p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDv0zRLfEED5G3NwhicqvoP2dQG6Mxe8fTwHB8KQ5fdeG9ro4IfRq2Ni4+iXtq0SE/zdwPUEe7MrhFj5L7qH8DEwHW8NtaK58n76+MkTpZKn+gkitauQHZBiYHLLZWcmrYDZ526xAxDRVT6NYEoY4Ryf3qxVtbvNgKCqrlT9w16VwIDAQAB;",
    spf: `v=spf1 a mx include:spf.${location.host} ~all`,
    smtpType: "general",
    returnPath: `rp.${location.host}`,
    user: 1,
    domain: 1
});

// *** Domain verification setup data ***
useQuery({
    queryKey: ["domain-verification-setup-query"],
    queryFn: () =>
        domainVerificationSetupInfoFn({
            domain_id: route.params.domainID
        }),
    onSuccess(data) {
        if ("message" in data && data.message.includes("SmtpConfig not exist for domain")) {
            setupData.value = {
                id: 1,
                dkim_record_name: "everp-api._domainkey",
                dkim: "v=DKIM1; t=s; h=sha256; p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDv0zRLfEED5G3NwhicqvoP2dQG6Mxe8fTwHB8KQ5fdeG9ro4IfRq2Ni4+iXtq0SE/zdwPUEe7MrhFj5L7qH8DEwHW8NtaK58n76+MkTpZKn+gkitauQHZBiYHLLZWcmrYDZ526xAxDRVT6NYEoY4Ryf3qxVtbvNgKCqrlT9w16VwIDAQAB;",
                // "v=spf1 a mx include:spf.mailerever.net ~all"
                spf: `v=spf1 a mx include:spf.${location.host} ~all`,
                smtpType: "general",
                // rp.mailerever.net
                returnPath: `rp.${location.host}`,
                user: 2,
                domain: 5
            };
        } else {
            setupData.value = data;
        }
    }
});

const goBack = () => router.push("/domains");
</script>

<template>
    <div class="dashboard-domain-verification-container">
        <DashboardHeader
            title="Domain Verification"
            subtitle="Domain Advance Option"
            description="Emails will be sent to your recipients from the verified domains"
            imageSrc="/images/views/dashboard/done.svg"
            imageAlternative="Domain verification image"
        >
            <template v-slot:content>
                <Button :radius="3" @click="goBack">Go Back</Button>
            </template>
        </DashboardHeader>

        <DashboardContainer padding="16px 24px">
            <DNSSetup parent="domains" :setupData="setupData" />
        </DashboardContainer>
    </div>
</template>
