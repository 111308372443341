<script setup>
// *** Vue ***
import { onMounted } from "vue";

// *** Vuex ***
import { useStore } from "vuex";

// *** Components ***
import BaseLayout from "@/layouts/base/baseLayout.vue";

// *** Mixin ***
import { useGeneralMixin } from "@/mixin/general/useGeneralMixin";

// *** Store instance ***
const store = useStore();

// *** General mixin ***
const { appHostName, generalCommitName, screenWidth } = useGeneralMixin();

// *** Mounted lifecycle method
onMounted(() => {
    // *** Change document title ***
    document.title = appHostName.value;

    // *** Set resize event in window ***
    window.addEventListener("resize", () => {
        if (screenWidth !== window.innerWidth) {
            store.commit(generalCommitName, {
                payloads: [
                    {
                        key: "screenWidth",
                        value: window.innerWidth
                    }
                ]
            });

            if (screenWidth < 1200) {
                store.commit(generalCommitName, {
                    payloads: [
                        {
                            key: "leftSidebarSituation",
                            value: false
                        }
                    ]
                });
            }
        }
    });
});
</script>

<template>
    <BaseLayout />
</template>

