<script setup>
// *** Vue ***
import { computed } from "vue";

// *** Router ***
import { useRouter, useRoute } from "vue-router";

// *** Vuex store ***
import { useStore } from "vuex";

// *** Mixin ***
import { useGeneralMixin } from "@/mixin/general/useGeneralMixin";

// *** Tanstack vue query ***
import { useMutation } from "@tanstack/vue-query";

// *** Mutation functions(callbacks) ***
import { logoutFn } from "@/api/auth/mutations/authAPIMutationsFn";

// *** Utils ***
import { getTokenStorage, clearStorage } from "@/utility/storage/storageUtility";

// *** Router ***
const router = useRouter();

// *** Route ***
const route = useRoute();

// *** Vuex store ***
const store = useStore();

// *** General mixin ***
const { generalCommitName, screenWidth, appHostName } = useGeneralMixin();

// *** Computeds ***
// # Left sidebar situation computed ***
const leftSidebarSituation = computed(() => store.state.general.leftSidebarSituation);

// # Is disabled left sidebar links computed #
const isDisabledLeftSidebarLinks = computed(() => store.state.general.isDisabledLeftSidebarLinks);

// # Active route name computed #
const activeRouteName = computed(() => route.name);

// *** Extra data ***
const menuItems = [
    {
        id: 1,
        routeName: "Dashboard/Main",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="29" height="29.935" viewBox="0 0 29 29.935"><ellipse cx="14.5" cy="14.968" rx="14.5" ry="14.968" transform="translate(0 0)" /><path d="M14.1,15.371V9.636a.389.389,0,0,0-.409-.4,6.145,6.145,0,1,0,6.541,6.541.389.389,0,0,0-.4-.409ZM19.114,16.4H13.482a.41.41,0,0,1-.41-.41V10.354A5.121,5.121,0,1,0,19.114,16.4Z" fill="#fff" fill-rule="evenodd"/><path d="M16.876,9.821a5.121,5.121,0,0,0-.935-.286v3.993h3.993a5.121,5.121,0,0,0-3.058-3.707Zm4.1,3.707a6.145,6.145,0,0,0-5.65-5.107.389.389,0,0,0-.409.4v5.326a.41.41,0,0,0,.41.41h5.326a.389.389,0,0,0,.4-.409A6.149,6.149,0,0,0,20.975,13.528Z" fill="#fff" fill-rule="evenodd"/><path d="M11.252,11.113a5.123,5.123,0,0,1,1.821-.76v5.632a.41.41,0,0,0,.41.41h5.632a5.121,5.121,0,1,1-7.863-5.282Z" fill="#fff"/><path d="M15.94,9.534a5.121,5.121,0,0,1,3.993,3.993H15.94Z" fill="#fff"/></svg>`,
        title: "Dashboard",
        isSubMenu: false,
        children: [],
        clickMethod: () => redirectAfterCLick("Dashboard/Main")
    },
    {
        id: 2,
        routeName: "Dashboard/Domains",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M215.4 96H144 107.8 96v8.8V144v40.4 89L.2 202.5c1.6-18.1 10.9-34.9 25.7-45.8L48 140.3V96c0-26.5 21.5-48 48-48h76.6l49.9-36.9C232.2 3.9 243.9 0 256 0s23.8 3.9 33.5 11L339.4 48H416c26.5 0 48 21.5 48 48v44.3l22.1 16.4c14.8 10.9 24.1 27.7 25.7 45.8L416 273.4v-89V144 104.8 96H404.2 368 296.6 215.4zM0 448V242.1L217.6 403.3c11.1 8.2 24.6 12.7 38.4 12.7s27.3-4.4 38.4-12.7L512 242.1V448v0c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64v0zM176 160H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>`,
        title: "SMTP / API emails",
        isSubMenu: false,
        children: [],
        clickMethod: () => redirectAfterCLick("Dashboard/Domains")
    },
    // {
    //     id: 2,
    //     routeName: "Dashboard/Incoming-Emails",
    //     icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>`,
    //     title: "Incoming emails",
    //     isSubMenu: false,
    //     children: [],
    //     clickMethod: () => redirectAfterCLick("Dashboard/Incoming-Emails")
    // },
    {
        id: 3,
        routeName: location.hostname.includes("inboxer.net")
            ? "https://app.inboxer.net/customer/guest/index"
            : "https://marketing.mailerever.com/customer/guest/index",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path fill="#439a97" d="M156.6 384.9L125.7 354c-8.5-8.5-11.5-20.8-7.7-32.2c3-8.9 7-20.5 11.8-33.8L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.3 0c2.4-4 4.8-7.7 7.2-11.3C289.1-4.1 411.1-8.1 483.9 5.3c11.6 2.1 20.6 11.2 22.8 22.8c13.4 72.9 9.3 194.8-111.4 276.7c-3.5 2.4-7.3 4.8-11.3 7.2v82.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9V380.8c-14.1 4.9-26.4 8.9-35.7 11.9c-11.2 3.6-23.4 .5-31.8-7.8zM384 168a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>`,
        title: "Marketing Platform",
        isSubMenu: false,
        children: [],
        clickMethod: () =>
            !isDisabledLeftSidebarLinks.value &&
            redirectAfterCLick(
                location.hostname.includes("inboxer.net")
                    ? "https://app.inboxer.net/customer/guest/index"
                    : "https://marketing.mailerever.com/customer/guest/index",
                true
            )
    },
    {
        id: 4,
        routeName: "Dashboard/SMTP-Email-Pricing",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M470.7 9.4c3 3.1 5.3 6.6 6.9 10.3s2.4 7.8 2.4 12.2l0 .1v0 96c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3L310.6 214.6c-11.8 11.8-30.8 12.6-43.5 1.7L176 138.1 84.8 216.3c-13.4 11.5-33.6 9.9-45.1-3.5s-9.9-33.6 3.5-45.1l112-96c12-10.3 29.7-10.3 41.7 0l89.5 76.7L370.7 64H352c-17.7 0-32-14.3-32-32s14.3-32 32-32h96 0c8.8 0 16.8 3.6 22.6 9.3l.1 .1zM0 304c0-26.5 21.5-48 48-48H464c26.5 0 48 21.5 48 48V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V304zM48 416v48H96c0-26.5-21.5-48-48-48zM96 304H48v48c26.5 0 48-21.5 48-48zM464 416c-26.5 0-48 21.5-48 48h48V416zM416 304c0 26.5 21.5 48 48 48V304H416zm-96 80a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"/></svg>`,
        title: "Pricing",
        isSubMenu: false,
        children: [],
        clickMethod: () => redirectAfterCLick("Dashboard/SMTP-Email-Pricing")
    },
    {
        id: 5,
        routeName: "Dashboard/Support",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"><path d="M14,0c7.72,0,14,5.432,14,12.11S21.72,24.22,14,24.22c-.362,0-.734-.013-1.111-.039-4.127,3.34-7.51,3.794-7.656,3.812A1,1,0,0,1,5.106,28a1.107,1.107,0,0,1-.963-.594,1.3,1.3,0,0,1,.047-1.318c.013-.02,1.1-1.7,2.242-3.784A11.764,11.764,0,0,1,0,12.11C0,5.432,6.28,0,14,0Zm0,21.792c6.484,0,11.76-4.344,11.76-9.682S20.484,2.428,14,2.428,2.24,6.771,2.24,12.11c0,3.557,2.376,6.823,6.2,8.524a1.177,1.177,0,0,1,.632.722,1.3,1.3,0,0,1-.08.992c-.419.815-.852,1.6-1.252,2.3a18.82,18.82,0,0,0,4.123-2.671,1.053,1.053,0,0,1,.784-.25C13.109,21.77,13.564,21.792,14,21.792Z" /></svg>',
        title: "Support",
        isSubMenu: false,
        children: [],
        clickMethod: () => redirectAfterCLick("Dashboard/Support")
    },
    {
        id: 6,
        routeName: "Dashboard/Profile",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12,0C5.095,0,.6,3.431.6,9.391V12A4.453,4.453,0,0,0,0,14.087a3.856,3.856,0,0,0,2.4,3.13C3.538,21.343,9,24,12,24s8.462-2.657,9.6-6.783A4.146,4.146,0,0,0,24,13.9a3.389,3.389,0,0,0-.6-1.9V9.391c0-7.036-5.8-7.432-5.8-7.432S15.889,0,12,0Zm0,2.087C15.637,2.087,16.209,4,16.209,4A5.154,5.154,0,0,1,21,9.391v2.087h-.212c-.551-2.106-2.075-4.726-4.907-5.424,0,0-2.755,3.612-11.286,3.337l-1.2,2.087H3V9.391C3,4.749,6.28,2.087,12,2.087Zm4.446,6.556a8.41,8.41,0,0,1,2.463,4.922h2.653a1.626,1.626,0,0,1,.038.337c0,1.151-2.071,1.876-2.071,1.876,0,3.455-5.479,6.135-7.529,6.135s-7.529-2.885-7.529-6.135c0,0-2.071-.519-2.071-1.691a2.345,2.345,0,0,1,.062-.522H4.88l1.2-2.092C11.189,11.4,14.164,10.6,16.446,8.643Z" /></svg>`,
        title: "Profile",
        isSubMenu: false,
        children: [],
        clickMethod: () => redirectAfterCLick("Dashboard/Profile")
    },
    {
        id: 7,
        routeName: null,
        icon: `<svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.6875 3.125C5.1019 3.125 5.49933 2.96038 5.79235 2.66735C6.08538 2.37433 6.25 1.9769 6.25 1.5625C6.25 1.1481 6.08538 0.750672 5.79235 0.457646C5.49933 0.16462 5.1019 0 4.6875 0H1.5625C1.1481 0 0.750672 0.16462 0.457646 0.457646C0.16462 0.750672 0 1.1481 0 1.5625V23.4375C0 23.8519 0.16462 24.2493 0.457646 24.5424C0.750672 24.8354 1.1481 25 1.5625 25H4.6875C5.1019 25 5.49933 24.8354 5.79235 24.5424C6.08538 24.2493 6.25 23.8519 6.25 23.4375C6.25 23.0231 6.08538 22.6257 5.79235 22.3326C5.49933 22.0396 5.1019 21.875 4.6875 21.875H3.125V3.125H4.6875ZM26.2812 11.5938L21.875 5.34375C21.636 5.00689 21.2734 4.77835 20.8664 4.70808C20.4595 4.63781 20.0412 4.73153 19.7031 4.96875C19.5341 5.08717 19.3903 5.23788 19.2799 5.4122C19.1694 5.58651 19.0946 5.78097 19.0598 5.98435C19.0249 6.18773 19.0307 6.396 19.0767 6.59714C19.1228 6.79829 19.2082 6.98831 19.3281 7.15625L22.0156 10.9375H9.375C8.9606 10.9375 8.56317 11.1021 8.27015 11.3951C7.97712 11.6882 7.8125 12.0856 7.8125 12.5C7.8125 12.9144 7.97712 13.3118 8.27015 13.6049C8.56317 13.8979 8.9606 14.0625 9.375 14.0625H21.875L19.0625 17.8125C18.9394 17.9767 18.8498 18.1634 18.7989 18.3622C18.748 18.561 18.7367 18.7678 18.7657 18.971C18.7947 19.1741 18.8635 19.3695 18.968 19.5461C19.0726 19.7226 19.2108 19.8769 19.375 20C19.6455 20.2028 19.9744 20.3125 20.3125 20.3125C20.5551 20.3125 20.7943 20.256 21.0113 20.1475C21.2282 20.0391 21.417 19.8816 21.5625 19.6875L26.25 13.4375C26.4501 13.1732 26.561 12.8522 26.5666 12.5207C26.5722 12.1893 26.4723 11.8646 26.2812 11.5938V11.5938Z"/></svg>`,
        title: "Logout",
        isSubMenu: false,
        children: [],
        clickMethod: () => logout()
    }
];

// *** Logout mutation ***
const { mutate: logoutMutate } = useMutation({
    mutationKey: ["logout-mutation"],
    mutationFn: logoutFn
});

// *** Toggle sidebar situation ***
const toggleSidebarSituation = (situation) => {
    store.commit(generalCommitName, {
        payloads: [
            {
                key: "leftSidebarSituation",
                value: situation
            }
        ]
    });
};

// *** Go to path ***
const redirectAfterCLick = (path, isLink = false) => {
    if (!isDisabledLeftSidebarLinks.value) {
        if (isLink) {
            window.open(path);
        } else {
            router.push({ name: path });
            toggleSidebarSituation(false);
        }
    }
};

// *** Call logout api ***
const logout = () => {
    // *** Get token storage utility function ***
    const tokens = getTokenStorage();

    // *** Clear storage 👉 remove all localstorage items like tokens and verify section after logout mutatoin completed ***
    clearStorage();

    // *** Hide sidebar ***
    toggleSidebarSituation(false);

    // *** Redirect to signin page ***
    router.push({ name: "Auth/Signin" });

    if (tokens !== null && tokens.refresh !== null) {
        // *** Remove token from DB ***
        logoutMutate({
            refresh_token: tokens.refresh,
            access_token: tokens.access
        });
    }
};

const isDisabled = (title) => {
    return title !== "Logout" && isDisabledLeftSidebarLinks.value;
};

// *** Route custom class ***
const routeClass = (item) => {
    if (item.routeName === activeRouteName.value || item.children.includes(activeRouteName.value)) {
        return `active-menu-item ${item.isSubMenu ? "submenu-item" : "menu-item"}`;
    }
    return `${item.isSubMenu ? "submenu-item" : "menu-item"}`;
};

// *** Left sidebar custom class ***
const leftSidebarCustomClass = () => {
    if (leftSidebarSituation.value && screenWidth.value <= 400) {
        return "dashboard-utility-left-sidebar-full-screen-open";
    } else if (leftSidebarSituation.value) {
        return "dashboard-utility-left-sidebar-full-width-open";
    } else if (leftSidebarSituation.value === false && screenWidth.value >= 1200) {
        return "dashboard-utility-left-sidebar-half-width-open";
    }

    toggleSidebarSituation(false);
    return "dashboard-utility-left-sidebar-zero";
};

// *** Left sidebar classes ***
const leftSidebarClasses = computed(() => [leftSidebarCustomClass()]);
</script>

<template>
    <div class="dashboard-utility-left-sidebar-container" :class="leftSidebarClasses">
        <div class="dashboard-utility-left-sidebar-logotype-and-menu">
            <div
                class="dashboard-utility-left-sidebar-logotype"
                :class="isDisabledLeftSidebarLinks && 'dashboard-utility-left-sidebar-disabled-logotype'"
            >
                <Router-Link to="/">
                    <Image
                        src="assets/images/logos/mailerever.svg"
                        :alt="`${appHostName} logo`"
                        width="60px"
                        height="60px"
                    />
                    <Typography
                        variant="heading6"
                        fontWeight="bold"
                        margin="-10px 0 0 0px"
                        padding="0 0 4px"
                        align="center"
                        :title="appHostName"
                        >{{ appHostName }}</Typography
                    >
                </Router-Link>
            </div>
            <div class="dashboard-utility-left-sidebar-menu">
                <div
                    v-for="item in menuItems"
                    :key="item.id"
                    :class="[routeClass(item), isDisabled(item.title) && 'dashboard-utility-left-sidebar-disable-item']"
                    @click="item.clickMethod()"
                    :title="item.isSubMenu ? item.title : ''"
                >
                    <Icon
                        v-if="item.isSubMenu"
                        width="20px"
                        height="20px"
                        color="var(--main-color)"
                        margin="0 auto"
                        type="element"
                        :element="item.icon"
                    />

                    <Icon
                        v-else
                        width="28px"
                        height="28px"
                        color="var(--main-color)"
                        margin="0 auto 8px"
                        type="element"
                        :element="item.icon"
                    />

                    <Typography variant="caption" align="center" margin="-0.16px 0 0">{{ item.title }}</Typography>
                </div>
            </div>
        </div>
        <Icon
            class="left-sidebar-icon"
            :name="leftSidebarSituation === true ? 'chevronLeft' : 'chevronRight'"
            width="32px"
            height="64px"
            color="var(--main-color)"
            padding="8px"
            background="var(--lighten-third-color-1)"
            @click="toggleSidebarSituation(!leftSidebarSituation)"
        />
        <Transition name="fade" mode="out-in" v-if="screenWidth <= 400">
            <div class="dashboard-utility-left-sidebar-close" @click="toggleSidebarSituation(false)">
                <Typography variant="subtitle1" fontWeight="bold" margin="4px 0 0" align="center"
                    >Close Sidebar</Typography
                >
            </div>
        </Transition>
    </div>
</template>
