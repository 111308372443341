// *** Vuex ***
import { createStore } from "vuex";

// *** Modules ***
import general from "./modules/general/generalStore";
import dashboard from "./modules/dashboard/dashboardStore";
import auth from "./modules/auth/authStore";
import icons from "./modules/icons/iconsStore";

// *** Define new instance of vuex ***
export default createStore({
    namespaced: true,
    modules: {
        general,
        dashboard,
        auth,
        icons
    }
});

