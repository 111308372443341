<script setup>
// *** Vue ***
import { defineProps, ref } from "vue";

// *** Vue router ***
import { useRoute, useRouter } from "vue-router";

// *** Tanstack vue query ***
import { useMutation } from "@tanstack/vue-query";

// *** Query functions(callback) ***
import { deleteTokenFn } from "@/api/dashbaord/mutations/dashboardAPIMutationsFn";

// *** Props ***
const props = defineProps({
    apiTokens: {
        type: Object,
        required: true
    },
    isFetched: {
        type: Boolean,
        required: true
    },
    isFetching: {
        type: Boolean,
        required: true
    },
    isLoading: {
        type: Boolean,
        required: true
    }
});

// *** Route ***
const route = useRoute();

// *** Router ***
const router = useRouter();

// *** Ref states ***
const tokenID = ref(0);
const copyRef = ref(null);

// *** Extra data ***
const tableHeader = [
    { id: 1, text: "Name", minWidth: "300px", maxWidth: "350px" },
    { id: 2, text: "API key", minWidth: "300px", maxWidth: "350px" }
];

// *** Open delete modal ***
const handleOpenDeleteModal = () => {
    router.push({
        query: {
            ...route.query,
            alert: "deleteTokenAlert"
        }
    });
};

// *** close delete modal ***
const closeOpenDeleteModal = () => {
    router.back();
};

// *** Delete token mutation ***
const { mutate: deleteTokenMutate } = useMutation({
    mutationKey: ["delete-token-mutation"],
    mutationFn: deleteTokenFn,
    onSuccess() {
        router.back();
    }
});

// *** Delete token ***
const deleteToken = () => {
    deleteTokenMutate({ token: tokenID });
};

// *** Copy api token ***
const copyAPIToken = (_, api_key) => {
    // *** Select copy svg element ***
    const fetchCopySvgElement = copyRef.value[0].children[0];

    // *** Copy text ***
    navigator.clipboard.writeText(api_key);

    // *** Change svg border and fill property value ***
    fetchCopySvgElement.style.border = "1px solid var(--third-color)";
    fetchCopySvgElement.children[0].style.fill = "var(--third-color)";

    // *** After 5 second reset border and fill property value
    setTimeout(() => {
        fetchCopySvgElement.style.border = "1px solid var(--main-color)";
        fetchCopySvgElement.children[0].style.fill = "var(--main-color)";
    }, 5000);
};
</script>

<template>
    <DashboardContainer class="dashboard-domain-management-api-token">
        <div class="dashboard-domain-management-api-token-titles-and-generate-token">
            <div class="dashboard-domain-management-api-token-title-and-subtitle">
                <Typography variant="heading4" color="var(--secondary-text-color)">API Token</Typography>
                <Typography variant="body2" color="var(--secondary-text-color)"
                    >API tokens authenticate requests made when sending emails and on server-specific
                    endpoints.</Typography
                >
            </div>
        </div>

        <Divider color="var(--background-color)" :radius="0" />

        <Table
            emptyListText="No token yet."
            :tableShow="props.isFetched && props.apiTokens.length > 0"
            :headers="tableHeader"
            :isLoading="props.isFetching || props.domainCredentialIsLoading"
        >
            <template v-slot:row>
                <tr v-for="(token, index) in props.apiTokens" :key="index">
                    <td
                        class="table-body-name"
                        :style="{ 'min-width': tableHeader[0].minWidth, 'max-width': tableHeader[0].maxWidth }"
                    >
                        <div class="table-body-flex-box">
                            <Icon
                                class="table-body-name-icon"
                                name="code"
                                width="30px"
                                height="30px"
                                padding="4.8px"
                                :margin="index == 0 ? '0px 6.4px 0 0' : '0px 6.4px'"
                            />
                            <Typography variant="body2">{{ token.apiUrl }}</Typography>
                        </div>
                    </td>
                    <td
                        class="table-body-api-token"
                        :style="{ 'min-width': tableHeader[1].minWidth, 'max-width': tableHeader[1].maxWidth }"
                    >
                        <div class="table-body-flex-box">
                            <Typography variant="body2">{{ token.apiToken }}</Typography>
                            <div ref="copyRef">
                                <Icon
                                    name="copy"
                                    width="30px"
                                    height="30px"
                                    padding="4.8px"
                                    margin="0 6.4px"
                                    @click="copyAPIToken($event, token.apiToken)"
                                />
                            </div>
                        </div>
                    </td>
                </tr>
            </template>
        </Table>

        <!-- <SweetAlert
            alertID="deleteTokenAlert"
            type="error"
            title="Delete Token"
            description="Are your sure delete this token?"
            @close="closeOpenDeleteModal"
        >
            <template v-slot:buttons>
                <Button hoverType="opacity" @click="deleteToken">Delete</Button>
                <Button :outlined="true" hoverType="opacity" @click="closeOpenDeleteModal">Cancel</Button>
            </template>
        </SweetAlert> -->
    </DashboardContainer>
</template>
