// *** Get auth api instance ***
import authAPI from "../authAPI";

// *** Sign in mutation function(callback) ***
const signInMutationFn = async (data) => {
    // *** Fetch token with post method ***
    const signInResponse = await authAPI.post("token/", data);

    // *** Return singin response data ***
    return signInResponse.data;
};

// *** Sign up mutation function(callback) ***
const signUpMutationFn = async (data) => {
    // *** Create new user with post method ***
    const signUpResponse = await authAPI.post("register/", data);

    // *** Return signup response data ***
    return signUpResponse.data;
};

// *** Forgot password function(callbak) ***
const forgotPasswordFn = async (data) => {
    // *** Send email by email with post method ***
    const forgotPasswordResponse = await authAPI.post("forget_password/", data);

    // *** Return forgot password response data ***
    return forgotPasswordResponse.data;
};

// *** Change password function(callback) ***
const changePasswordFn = async (data) => {
    // *** Change password after send reset password email with post method ***
    const changePasswordResponse = await authAPI.post("changepassword/", data);

    // *** Return change password response data ***
    return changePasswordResponse.data;
};

const refreshTokenFn = async (data) => {
    // *** Refresh token with post method ***
    const refreshTokenResponse = await authAPI.post("token/refresh/", data);

    // *** Return refresh token response data ***
    return refreshTokenResponse.data;
};

const logoutFn = async (data) => {
    // *** Logout token with post method ***
    const logoutResponse = await authAPI.post("logout/", {
        refresh_token: data.refresh_token
    });

    // *** Add aditional header (set authorization header) ***
    logoutResponse.headers.setAuthorization(`Token ${data.access_token}`);

    // *** Return logout response data ***
    return logoutResponse.data;
};

// *** Export mutation fn ***
export { changePasswordFn, forgotPasswordFn, logoutFn, refreshTokenFn, signInMutationFn, signUpMutationFn };
