// *** Vue router  ***
import { createRouter, createWebHistory } from "vue-router";

// *** Storage util ***
import {
    getTokenStorage,
    getVerifyStorage,
    setVerifyStorage,
    setTokenStorage,
    setUserInfoStorage
} from "@/utility/storage/storageUtility";

// *** Dashboard routes ***
import DashboardRoutes from "./routes/dashboard/dashboardRoute";

// *** Auth routes ***
import AuthRoutes from "./routes/auth/authRoutes";

// *** Error routes
import ErrorRoutes from "./routes/errors/errorsRoutes";

// *** Router history ***
const routerHistory = createWebHistory(process.env.BASE_URL);

// *** Routes ***
const routes = [...DashboardRoutes, ...AuthRoutes, ...ErrorRoutes];

// *** Scroll behavior funciton ***
const scrollBehavior = (to, from, savedPosition) => {
    if (to.name === from.name) {
        return savedPosition;
    } else {
        return {
            top: 0,
            left: 0
        };
    }
};

// *** Create router instance
const router = createRouter({
    history: routerHistory,
    routes: routes,
    scrollBehavior: scrollBehavior
});

// *** Router before guard ***
router.beforeEach((to) => {
    // *** Get token storage utility function ***
    const tokens = getTokenStorage();

    // Get verify storage utility function ***
    const userIsVerified = getVerifyStorage();

    if (to.path === "/" && to.query.token) {
        // *** Set verify to localstorage ***
        setVerifyStorage(false);

        // *** Set token to localstorage ***
        setTokenStorage(to.query.token, null);

        // *** Set user information to localstorage ***
        setUserInfoStorage("", "", "");

        return true;
    } else if (to.path === "/auth/") {
        // *** When user is verified 👉 redirect to dashboard main ***
        if (userIsVerified !== null && userIsVerified) return { name: "Dashboard/Main" };

        // *** Redirect to authentication verificaiton page ***
        return { name: "Auth/Verification" };
    } else {
        // *** Was user authenticated? ***
        const isAuthenticate = tokens != null && tokens.access;

        // *** When user authenticated and route name includes auth 👉 redirect to dashboard main page ***
        if (isAuthenticate && to.name.includes("Auth")) return { name: "Dashboard/Main" };

        // *** When user not authenticated and route name includes authe/change-password and strCode query params less than 30 characters 👉 redirect to auth signin page ***
        if (isAuthenticate === false && to.name === "Auth/Change-Password" && to.params.strCode.length < 30)
            return { name: "Auth/Signin" };

        // *** When user not authenticated and route name includes dashboard 👉 redirect to auth signin page ***
        if (isAuthenticate === false && to.name.includes("Dashboard")) return { name: "Auth/Signin" };
    }
});

export default router;
