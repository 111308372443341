// *** Get dashboard api instance ***
import dashboardAPI from "../dashboardAPI";

// *** Verify domain mutation function(callback) ***
const verifyDomainFn = async (data) => {
    // *** Verify domain information with get method ***
    const verifyDomainResponse = await dashboardAPI.get("check/", {
        params: {
            domain_id: data.domain_id
        }
    });

    // *** Return verify domain response data ***
    return verifyDomainResponse.data;
};

// *** Generate new token mutation function(callback) ***
const generateNewTokenFn = async (data) => {
    // *** Generate new token with post method ***
    const generateNewTokenResponse = await dashboardAPI.post("generate_new_token/", data);

    // *** Return generate token response data ***
    return generateNewTokenResponse.data;
};

// *** Delete token mutation function(callback) ***
const deleteTokenFn = async (data) => {
    // *** Delete token with delete method ***
    const deleteTokenResponse = await dashboardAPI.delete("delete_token/", {
        params: data
    });

    // *** Return delete token response data ***
    return deleteTokenResponse.data;
};

// *** Change track mutation function(callback) 👉 change inbox tracker status ***
const changeTrackFn = async (data) => {
    // *** change track with update method ***
    const changeTrackResponse = await dashboardAPI.put("inboxtrackerstatus/", data);

    // *** Return change track response data ***
    return changeTrackResponse.data;
};

// *** Dynamic warmup mutation function(callback) ***
const dynamicWarmupFn = async (data) => {
    // *** Active or deactive dynamic warmup with put method ***
    const dynamicWarmupResponse = await dashboardAPI.put("dynamicwarmup/", data);

    // *** Return dynamic warmup response data ***
    return dynamicWarmupResponse.data;
};

// *** Reset smtp password mutation function(callback) ***
const resetSMTPPasswordFn = async (data) => {
    // *** Reset smtp password with put method ***
    const resetSMTPPasswordResponse = await dashboardAPI.put("reset_smtp_password/", data);

    // *** Return reset smtp password response data ***
    return resetSMTPPasswordResponse.data;
};

// *** Create new domain mutation function(callback) ***
const createNewDomainFn = async (data) => {
    // *** Create new domain with post method ***
    const createNewDomainResponse = await dashboardAPI.post("store/", data);

    // *** Return create new domain response data ***
    return createNewDomainResponse.data;
};

// *** Create incoming email mutation function(callback) ***
const createIncomingEmailFn = async (data) => {
    // *** Create incoming email with post method ***
    const createIncomingEmailResponse = await dashboardAPI.post("incommingemail/", data);

    // *** Return create incoming email response data ***
    return createIncomingEmailResponse.data;
};

// *** Change incoming email password mutation function(callback) ***
const changeIncomingEmailPasswordFn = async (data) => {
    // *** Change incoming email password url ***
    const changePasswordAPIURL = `incomingemail/${data.email_id}/`;

    // *** Change incoming email password with put method ***
    const changeIncomingEmailPasswordResponse = await dashboardAPI.put(changePasswordAPIURL, {
        password: data.password
    });

    // *** Return change incoming email password response data ***
    return changeIncomingEmailPasswordResponse.data;
};

// *** Delete incoming email mutation function(callback) ***
const deleteIncomingEmailFn = async (data) => {
    // *** delete incoming email url ***
    const incomingEamilAPIURL = `incomingemail/${data.email_id}`;

    // *** Delete incoming email with delete method ***
    const deleteIncomingEmailResponse = await dashboardAPI.delete(incomingEamilAPIURL);

    // *** Return delete incoming email response data ***
    return deleteIncomingEmailResponse.data;
};

// *** Create incoming domain mutation function(callback)
const createIncomingDomainFn = async (data) => {
    // *** Create incoming domain with post method ***
    const createIncomingDomainResponse = await dashboardAPI.post("incommingdomain/", data);

    // *** Return create incoming domain response data ***
    return createIncomingDomainResponse.data;
};

// *** Verify email mutation funciton(callback) ***
const verifyEmailFn = async (data) => {
    // *** Verify email with post method ***
    const verifyEmailResponse = await dashboardAPI.post("verify_email", data);

    // *** Return verify email response data ***
    return verifyEmailResponse.data;
};

// *** Update profile mutation function(callback) ***
const updateProfileFn = async (data) => {
    // *** Update profile with put method ***
    const updateProfileResponse = await dashboardAPI.put("profile/", data);

    // *** Return update profile response data ***
    return updateProfileResponse.data;
};

// *** Change passowrd mutation function(callback) ***
const changePasswordFn = async (data) => {
    // *** Change password with put method ***
    const changePasswordResponse = await dashboardAPI.put("changepassword/", data);

    // *** Return change password response data ***
    return changePasswordResponse.data;
};

// *** Create ticket (support) mutation function(callback) ***
const createTicketFn = async (data) => {
    // *** Create ticket with post method ***
    const createTicketResponse = await dashboardAPI.post("support/", data);

    // *** Return create ticket response data ***
    return createTicketResponse.data;
};

export {
    verifyDomainFn,
    generateNewTokenFn,
    deleteTokenFn,
    changeTrackFn,
    dynamicWarmupFn,
    resetSMTPPasswordFn,
    createNewDomainFn,
    createIncomingEmailFn,
    changeIncomingEmailPasswordFn,
    deleteIncomingEmailFn,
    createIncomingDomainFn,
    verifyEmailFn,
    updateProfileFn,
    changePasswordFn,
    createTicketFn
};
