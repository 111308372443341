<script setup>
// *** Vue ***
import { ref, reactive, defineProps, watch } from "vue";

// *** Route ***
import { useRoute, useRouter } from "vue-router";

// *** Tanstack vue query ***
import { useQuery, useMutation } from "@tanstack/vue-query";

// *** Query functions(callback) ***
import { domainManageListFn } from "@/api/dashbaord/queries/dashboardAPIQueriesFn";
import { dynamicWarmupFn } from "@/api/dashbaord/mutations/dashboardAPIMutationsFn";

// *** Components ***
import Reputation from "./Reputation/DomainManagementReputation.vue";
import SendAndReceive from "./SendAndReceive/DomainManagementSendAndReceive.vue";

// *** Define props ***
const props = defineProps({
    reputationReceiveData: {
        type: Object,
        required: true
    }
});

// *** Route instance ***
const route = useRoute();

// *** Router instance ***
const router = useRouter();

// *** Ref states ***
const bounceRate = ref(0);
const dynamicWarmupModel = ref(false);

// *** Dynamic warmup model watch ***
watch(
    () => props.reputationReceiveData,
    () => {
        dynamicWarmupModel.value = props.reputationReceiveData.dynamicWarmup;
    }
);

// *** Reactive state ***
let manageData = reactive({
    id: 0,
    incoming: 0,
    outgoing: 0,
    bounces: 0,
    held: 0
});

// *** Dynamic warmup mutation ***
const { isLoading: dynamicWarmupIsLoading, mutate: dynamicWarmupMutate } = useMutation({
    mutationKey: ["dynamic-warmup-mutation"],
    mutationFn: dynamicWarmupFn,
    onSuccess() {
        router.back();
    }
});

// *** Domain manage list query ***
useQuery({
    queryKey: ["domain-manage-list"],
    queryFn: () => domainManageListFn({ domain_id: route.params.domainID }),
    onSuccess(data) {
        const manageList = data.message;
        if (!manageList || manageList.length == 0 || typeof manageList === "string") {
            manageData = Object.assign(manageData, {
                id: 0,
                incoming: 0,
                outgoing: 0,
                bounces: 0,
                held: 0
            });
        } else {
            manageData = Object.assign(manageData, {
                id: manageList.id,
                held: manageList.held.toLocaleString(),
                incoming: manageList.incoming.toLocaleString(),
                outgoing: manageList.outgoing.toLocaleString(),
                bounces: manageList.bounces.toLocaleString()
            });
            bounceRate.value =
                manageList.bounces === 0 || manageList.outgoing === 0
                    ? 0
                    : (manageList.bounces / manageList.outgoing) * 100 < 100
                    ? Math.round((manageList.bounces / manageList.outgoing) * 100)
                    : 100;
        }
    }
});

const handleSwitchChange = (ev) => {
    if (ev.target.checked) {
        router.push({
            query: {
                ...route.query,
                alert: "dynamicWarmupAlert"
            }
        });
    } else {
        dynamicWarmupMutate({ credenttial_id: props.reputationReceiveData.credentialID, dynamic_warmup: false });
    }
};

// *** Handle do it button ***
const handleDoItButton = () => {
    dynamicWarmupMutate({ credenttial_id: props.reputationReceiveData.credentialID, dynamic_warmup: true });
};

// *** Handle close modal ***
const handleCloseModalButton = () => {
    dynamicWarmupModel.value = false;
    router.back();
};
</script>

<template>
    <DashboardContainer class="dashboard-domain-management-reputation-and-send-and-receive">
        <div class="dashboard-domain-management-reputation-and-send-and-receive-header">
            <Typography variant="heading4" color="var(--secondary-text-color)" :inline="true">
                <div class="domain-with-email-per-hour">
                    {{ route.query.domain }}
                    <Typography
                        variant="body2"
                        fontWeight="bold"
                        margin="3.2px 0 0 4.8px"
                        color="var(--secondary-text-color)"
                        >(Email per hour {{ props.reputationReceiveData.sendLimit }})</Typography
                    >
                </div>
                <div class="label-with-switch">
                    <Typography variant="label" fontWeight="bold" color="var(--secondary-text-color)"
                        >Dynamic Warmup</Typography
                    >
                    <label class="switch">
                        <input type="checkbox" v-model="dynamicWarmupModel" @change="handleSwitchChange" />
                        <span class="slider round"></span>
                    </label>
                </div>
            </Typography>
        </div>
        <div class="dashboard-domain-management-reputation-and-send-and-receive-content">
            <Reputation :bounceRate="bounceRate" />
            <SendAndReceive :manage="manageData" />
        </div>

        <SweetAlert
            alertID="dynamicWarmupAlert"
            type="info"
            title="Dynamic Warmup"
            description="By activating dynamic warm-up, the cost of your service will be 2.5x."
            @close="handleCloseModalButton"
        >
            <template v-slot:buttons>
                <Button hoverType="opacity" @click="handleDoItButton" :loading="dynamicWarmupIsLoading">Do it</Button>
                <Button
                    :outlined="true"
                    hoverType="opacity"
                    :loading="dynamicWarmupIsLoading"
                    @click="handleCloseModalButton"
                    >Cancel</Button
                >
            </template>
        </SweetAlert>
    </DashboardContainer>
</template>
