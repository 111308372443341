<script setup>
// *** Vue ***
import { ref, reactive } from "vue";

// *** Vue router ***
import { useRouter } from "vue-router";

// *** Tanstack vue query ***
import { useMutation } from "@tanstack/vue-query";

// *** Mixin ***
import { useGeneralMixin } from "@/mixin/general/useGeneralMixin";

// *** Mutation functions(callbacks) ***
import { signUpMutationFn, signInMutationFn } from "@/api/auth/mutations/authAPIMutationsFn";

// *** Router ***
const router = useRouter();

// *** General mixin ***
const { appHostName } = useGeneralMixin();

// *** Signup related states ***
const company = ref("");
const name = ref("");
const email = ref("");
const password = ref("");
const isAcceptTerms = ref(false);

// *** Extra data ***
const blogPath = location.hostname.includes("inboxer.net") ? "https://inboxer.net/blog" : "https://mailerever.com/blog";
const features = [
    "Try it with 100 emails, then upgrade and grow your business with 3,000 FREE emails/month",
    "Drag & drop and HTML email editors",
    "Professionally-designed email templates with dynamic targeting",
    "Real-time analytics, email tracking and log retention",
    "Email API, SMTP relay, webhooks and clean documentation",
    "Always 24/7 customer support",
    "Upgrade anytime to Premium features including multiple domains, deliverability consultation, live chat support and more"
];

// *** Reactivity states ***
const errorToggleHandling = reactive({
    company: false,
    name: false,
    email: false,
    password: false
});

const errorTextHandling = reactive({
    company: "",
    name: "",
    email: "",
    password: ""
});

// *** Utils ***
import { emailRegex } from "@/utility/regex/regexUtility";
import { setTokenStorage, setUserInfoStorage, setVerifyStorage } from "@/utility/storage/storageUtility";

// *** Signup mutation ***
const { isLoading: signUpLoading, mutate: signUpMutate } = useMutation({
    mutationKey: ["sign-up-mutation"],
    mutationFn: signUpMutationFn
});

// *** Signin mutation ***
const { isLoading: signInLoading, mutate: signInMutatate } = useMutation({
    mutationKey: ["sign-in-mutation"],
    mutationFn: signInMutationFn
});

// *** Get tokens or call signin mutation ***
const getSignInToken = () => {
    signInMutatate(
        {
            username: email.value,
            password: password.value
        },
        {
            onSuccess({ data }) {
                // *** Save access and refresh token in localstorage ***
                setTokenStorage(data.access_token, data.refresh_token);

                // *** Save user information in localstorage ***
                setUserInfoStorage(data.name, data.username, data.email);

                // *** Save user is verified in localstorage ***
                setVerifyStorage(data.is_verified);

                // *** After save storage , redirect to main dashboard page ***
                router.push({ name: "Dashboard/Main" });
            }
        }
    );
};

// *** Signup form submit ***
const handleSignupForm = () => {
    // *** When user accept terms value is true run block's code ***
    if (isAcceptTerms.value) {
        // *** Reset error toggle handling ***
        errorToggleHandling.company = false;
        errorToggleHandling.name = false;
        errorToggleHandling.email = false;
        errorToggleHandling.password = false;

        // *** Company validation ***
        if (company.value.length < 3) {
            errorToggleHandling.company = true;
            errorTextHandling.company = "Company name must be at least 3 characters.";
        }

        // *** Name validation ***
        if (name.value.length < 3) {
            errorToggleHandling.name = true;
            errorTextHandling.name = "Name must be at least 3 characters.";
        }

        // *** Email validation ***
        if (emailRegex.test(email.value) === false) {
            errorToggleHandling.email = true;
            errorTextHandling.email = "Please enter a valid email address.";
        }

        // *** Password validation ***
        if (password.value.length < 8) {
            errorToggleHandling.password = true;
            errorTextHandling.password = "Password must be at least 8 characters.";
        }

        // *** Call apis after validation is successful ***
        if (
            !errorToggleHandling.company &&
            !errorToggleHandling.name &&
            !errorToggleHandling.email &&
            !errorToggleHandling.password
        ) {
            signUpMutate(
                {
                    company: company.value,
                    first_name: name.value,
                    email: email.value,
                    password: password.value
                },
                {
                    onSuccess: getSignInToken
                }
            );
        }
    }
};
</script>

<template>
    <div class="auth-signup">
        <div class="left-section">
            <div class="header">
                <Router-Link to="/" class="logo">
                    <Image
                        src="assets/images/logos/mailerever.svg"
                        :alt="`${appHostName} logo`"
                        width="60px"
                        height="60px"
                    />
                    <Typography variant="heading5" color="var(--third-text-color)">{{ appHostName }}</Typography>
                </Router-Link>
            </div>
            <div class="content">
                <div class="title-and-subtitle">
                    <Typography variant="heading4" color="var(--secondary-text-color)"
                        >Get started with a free account</Typography
                    >
                    <Typography variant="subtitle1" color="var(--third-text-color)">Sign up in seconds!</Typography>
                </div>
                <form @submit.prevent="handleSignupForm" class="form">
                    <Input
                        type="text"
                        id="signup-company-input"
                        labelText="Company"
                        inputColor="var(--third-text-color)"
                        labelBackgroundColor="var(--background-color)"
                        margin="0 auto 18px"
                        v-model="company"
                        :hasError="errorToggleHandling.company"
                        :errorText="errorTextHandling.company"
                    />

                    <Input
                        type="text"
                        id="signup-name-input"
                        labelText="Name"
                        inputColor="var(--third-text-color)"
                        labelBackgroundColor="var(--background-color)"
                        margin="0 auto 18px"
                        v-model="name"
                        :hasError="errorToggleHandling.name"
                        :errorText="errorTextHandling.name"
                    />

                    <Input
                        type="email"
                        id="signup-email-input"
                        labelText="Email"
                        inputColor="var(--third-text-color)"
                        labelBackgroundColor="var(--background-color)"
                        margin="0 auto 18px"
                        v-model="email"
                        :hasError="errorToggleHandling.email"
                        :errorText="errorTextHandling.email"
                    />

                    <Input
                        type="password"
                        id="signup-password"
                        labelId="signup-password-label"
                        labelText="Password"
                        inputColor="var(--third-text-color)"
                        labelBackgroundColor="var(--background-color)"
                        margin="0 auto 18px"
                        :hasError="errorToggleHandling.password"
                        :errorText="errorTextHandling.password"
                        v-model="password"
                    />

                    <div class="password-conditions">
                        <div class="password-condition">
                            <div
                                class="password-condition-dot"
                                :class="password.search(/[a-z]/gm) != -1 && 'password-condition-dot-success'"
                            ></div>
                            <Typography
                                variant="subtitle2"
                                color="var(--third-text-color)"
                                class="password-condition-text"
                                >One lowercase character</Typography
                            >
                        </div>

                        <div class="password-condition">
                            <div
                                class="password-condition-dot"
                                :class="password.search(/[A-Z]/gm) != -1 && 'password-condition-dot-success'"
                            ></div>
                            <Typography
                                variant="subtitle2"
                                color="var(--third-text-color)"
                                class="password-condition-text"
                                >One uppercase character</Typography
                            >
                        </div>

                        <div class="password-condition">
                            <div
                                class="password-condition-dot"
                                :class="password.search(/[0-9]/gm) != -1 && 'password-condition-dot-success'"
                            ></div>
                            <Typography
                                variant="subtitle2"
                                color="var(--third-text-color)"
                                class="password-condition-text"
                                >One number</Typography
                            >
                        </div>

                        <div class="password-condition">
                            <div
                                class="password-condition-dot"
                                :class="password.length >= 8 && 'password-condition-dot-success'"
                            ></div>
                            <Typography
                                variant="subtitle2"
                                color="var(--third-text-color)"
                                class="password-condition-text"
                                >8 characters minimum</Typography
                            >
                        </div>
                    </div>

                    <div class="checkbox-container">
                        <Checkbox
                            :labelText="`I agree to the <a href='${blogPath}' target='_blank'>Terms of Use</a>, <a href='${blogPath}' target='_blank'>Privacy Policy</a> and the <a href='${blogPath}' target='_blank'>Anti-Spam Policy</a>`"
                            checkboxColor="var(--third-text-color)"
                            indicatorColor="var(--grey-color-1)"
                            margin="8px 0 0"
                            v-model="isAcceptTerms"
                        />
                    </div>
                    <Button
                        type="submit"
                        fullWidth
                        :radius="1"
                        margin="20px auto 0"
                        :disabled="!isAcceptTerms"
                        :loading="signUpLoading || signInLoading"
                        >Sign up</Button
                    >
                </form>
                <div class="auth-signup-to-signin-account">
                    <Typography variant="subtitle1">Do your have an account?</Typography>&nbsp;
                    <router-link class="auth-signin-signup-link" to="/auth/signin">signin</router-link>
                </div>
            </div>
        </div>
        <div class="right-section">
            <div class="content">
                <Typography variant="heading4" color="var(--white-color)" margin="0 auto 20px"
                    >A smarter transactional email experience includes:</Typography
                >
                <div class="features">
                    <div class="feature-item" v-for="feature in features" :key="feature">
                        <div class="feature-dot"></div>
                        <Typography variant="subtitle1" color="var(--darken-white-color-2)" class="feature-text">{{
                            feature
                        }}</Typography>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
