<script setup>
// *** Vue ***
import { ref } from "vue";

// *** Vue router ***
import { useRoute } from "vue-router";

// *** Tanstack vue query ***
import { useQuery } from "@tanstack/vue-query";

// *** Mutation functions(callbacks) ***
import { domainActivityFn } from "@/api/dashbaord/queries/dashboardAPIQueriesFn";

// *** Route instance ***
const route = useRoute();

// *** Ref state ***
const search = ref("");
const beforeSearch = ref("");
const searchBy = ref("all");
const beforeSearchBy = ref("all");
const activePage = ref(1);
const countOfExistsField = ref(20);
const activityList = ref([]);

// *** Domain activity query ***
const {
    isFetched: activityIsFetched,
    isFetching: activityIsFetching,
    isLoading: activityIsLoading,
    refetch: refetchAcitity
} = useQuery({
    queryKey: ["domain-acitivy-query", activePage.value],
    queryFn: async () =>
        domainActivityFn({
            searchBy: searchBy.value,
            search: search.value,
            domainID: route.params.domainID,
            activePage: activePage.value
        }),
    onSuccess(data) {
        activityList.value = data;
        countOfExistsField.value = activityList.value.length;
    }
});

// *** Extra data ***
const tableHeader = [
    { id: 1, text: "Event", minWidth: "120px", maxWidth: "150px" },
    { id: 2, text: "Recipient", minWidth: "200px", maxWidth: "230px" },
    { id: 3, text: "Subject", minWidth: "300px", maxWidth: "350px" },
    { id: 4, text: "date", minWidth: "150px", maxWidth: "180px" }
];
const options = [
    { id: 1, text: "All", value: "all" },
    { id: 2, text: "Event", value: "status" },
    { id: 3, text: "Recipient", value: "rcpt_to" },
    { id: 4, text: "Subject", value: "subject" },
    { id: 5, text: "Date", value: "date" }
];

// *** Status chip type ***
const statusChipType = (status) => {
    return status.toLowerCase() === "sent" ? "success" : "error";
};

// *** Timestamp to date ***
const timestampToDate = (timestamp) => {
    const splitedTimestamp = String(timestamp).split(".");
    const concatinationTimestamp = Number(
        splitedTimestamp[0] + splitedTimestamp[1].slice(0, splitedTimestamp[1].length - 3)
    );
    const timestampToDate = new Date(concatinationTimestamp);

    return timestampToDate.toDateString();
};

// *** Change activity page ***
const changeActivityPage = (page) => {
    activePage.value = page;
    refetchAcitity();
};

// *** Handle search activity ***
const handleSearchActivity = () => {
    activePage.value = 1;
    beforeSearch.value = search.value;
    beforeSearchBy.value = searchBy.value;
    refetchAcitity();
};
</script>

<template>
    <DashboardContainer class="dashboard-domain-management-activity">
        <div class="dashboard-domain-management-activity-titles-and-search">
            <div class="dashboard-domain-management-activity-title-and-subtitle">
                <Typography variant="heading4" color="var(--secondary-text-color)">Activity</Typography>
                <Typography variant="body2" color="var(--secondary-text-color)" margin="0 8px 0 0"
                    >Find all emails, and their states, sent from your domains.</Typography
                >
            </div>
            <div class="dashboard-domain-management-activity-search">
                <Selectbox
                    selectboxName="searchBy"
                    labelText="Search By"
                    margin="0 8px 0 0"
                    selectboxColor="var(--secondary-text-color)"
                    :options="options"
                    v-model="searchBy"
                />
                <div class="dashboard-domain-management-activity-search-input-and-button">
                    <Input
                        type="text"
                        id="domain-search-activity-input"
                        labelId="domain-search-activity-label"
                        labelText="Search"
                        width="180px"
                        margin="0 8px 0 0"
                        inputColor="var(--main-text-color)"
                        :disabled="searchBy !== 'all' ? false : true"
                        v-model="search"
                    />
                    <Button
                        size="small"
                        margin="0"
                        @click="handleSearchActivity"
                        :disabled="
                            searchBy === beforeSearchBy ||
                            (searchBy !== 'all' &&
                                (search.length === 0 ||
                                    searchBy.length === 0 ||
                                    (search === beforeSearch && searchBy === beforeSearchBy)))
                        "
                        :loading="activityIsFetching || activityIsLoading"
                    >
                        <Icon name="search" width="16px" height="16px" color="var(--white-color)" />
                    </Button>
                </div>
            </div>
        </div>

        <Divider color="var(--background-color)" :radius="0" />

        <Table
            emptyListText="No activity yet."
            :tableShow="activityIsFetched && activityList.length > 0"
            :headers="tableHeader"
            :isLoading="activityIsFetching || activityIsLoading"
        >
            <template v-slot:row>
                <tr v-for="(activity, index) in activityList" :key="index">
                    <td :style="{ 'min-width': tableHeader[0].minWidth, 'max-width': tableHeader[0].maxWidth }">
                        <Chip :type="statusChipType(activity.status)" :text="activity.status" />
                    </td>
                    <td
                        v-text="activity.rcpt_to"
                        :style="{ 'min-width': tableHeader[1].minWidth, 'max-width': tableHeader[1].maxWidth }"
                    ></td>
                    <td
                        v-text="activity.subject"
                        :style="{ 'min-width': tableHeader[2].minWidth, 'max-width': tableHeader[2].maxWidth }"
                    ></td>
                    <td
                        v-text="timestampToDate(activity.timestamp)"
                        :style="{ 'min-width': tableHeader[3].minWidth, 'max-width': tableHeader[3].maxWidth }"
                    ></td>
                </tr>
            </template>
        </Table>

        <ArrowPagination
            v-show="!activityIsFetching && !activityIsLoading"
            :activePage="activePage"
            :perPage="20"
            :countOfExistsField="countOfExistsField"
            @update="changeActivityPage"
        />
    </DashboardContainer>
</template>
