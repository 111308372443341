export default {
    // *** Before component mount ***
    beforeMount(el, binding) {
        // *** Create click native button event in el object ***
        el.clickOutsideEvent = (event) => {
            // Here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // And if it did, call method provided in attribute value
                binding.value();
            }
        };

        // *** Define click event with clickOutsideEvent callback ***
        document.addEventListener("click", el.clickOutsideEvent);
    },

    // *** When Component unmounted ***
    unmounted(el) {
        // *** Remove keydown event ***
        document.removeEventListener("click", el.clickOutsideEvent);
    }
};

