<script setup>
// *** Vue ***
import { ref, reactive } from "vue";

// *** Vue router ***
import { useRouter, useRoute } from "vue-router";

// *** Components ***
import InboxTracker from "./InboxTracker/DomainManagementInboxTracker.vue";
import ReputationAndSendAndReceive from "./ReputationAndSendAndReceive/DomainManagementReputationAndSendAndReceive.vue";
import APIToken from "./APIToken/DomainManagementAPIToken.vue";
import SMTP from "./SMTP/DomainManagementSMTPComponent.vue";
import APIInstruductions from "./APIInstruductions/DomainManagementAPIInstruductions.vue";
import Activity from "./Activity/DomainManagementActivity.vue";

// *** Tanstack vue query ***
import { useQuery } from "@tanstack/vue-query";

// *** Query functions(callback) ***
import { domainCredentialFn } from "@/api/dashbaord/queries/dashboardAPIQueriesFn";

// *** Router instance ***
const router = useRouter();

// *** Route instance ***
const route = useRoute();

// *** Reactive states ***
const reputationReceiveData = reactive({ sendLimit: 0, dynamicWarmup: false, credentialID: 0 });
const smtpData = reactive({ smtpUsername: "", smtpToken: "", smtpHost: "", smtpPort: 0 });

// *** Ref states ***
const apiTokens = ref([]);

// *** Domain credential function(callback) ***
const {
    isFetched: domainCredentialIsFetched,
    isLoading: domainCredentialIsLoading,
    isFetching: domainCredentialIsFetching,
    data: domainCredentialData
} = useQuery({
    queryKey: ["domain-credential-query"],
    queryFn: () => domainCredentialFn({ domain_id: route.params.domainID }),
    onSuccess(data) {
        // *** Select first domain ***
        const selectDomain = data[0];

        // *** Change reputation receive data ***
        reputationReceiveData.sendLimit = selectDomain.rate_limit;
        reputationReceiveData.dynamicWarmup = selectDomain.dyanmicWarmup;
        reputationReceiveData.credentialID = domainCredentialData.id;

        // *** Change smtp data ***
        smtpData.smtpToken = selectDomain.smtpToken;
        smtpData.smtpHost = selectDomain.smtpHost;
        smtpData.smtpUsername = selectDomain.smtpUsername;
        smtpData.smtpPort = selectDomain.smtp_port;

        // *** Set api tokens ***
        apiTokens.value = data;
    }
});

// *** Go to back ***
const goToBack = () => router.back();
</script>

<template>
    <div class="dashboard-domain-management-component-container">
        <DashboardHeader
            title="Manage"
            subtitle="Domain Management"
            description="Here you can see tokens, activities, api instructions, logs, etc."
            imageSrc="/images/views/dashboard/goals.svg"
            imageAlternative="Profile Image"
        >
            <template v-slot:content>
                <Button :radius="3" @click="goToBack">Go Back</Button>
            </template>
        </DashboardHeader>

        <InboxTracker />

        <ReputationAndSendAndReceive :reputationReceiveData="reputationReceiveData" />

        <SMTP :smtpData="smtpData" />

        <APIToken
            :apiTokens="apiTokens"
            :isFetched="domainCredentialIsFetched"
            :isFetching="domainCredentialIsFetching"
            :isLoading="domainCredentialIsLoading"
        />

        <APIInstruductions />

        <Activity />
    </div>
</template>
