<script setup>
// *** Vue ***
import { defineProps, reactive, defineEmits, watch } from "vue";

// *** Tanstack vue query ***
import { useQuery } from "@tanstack/vue-query";

// *** Query functions(callback) ***
import { domainActivityFn } from "@/api/dashbaord/queries/dashboardAPIQueriesFn";

// *** Components ***
import EmptyList from "@/components/UI/Utility/EmptyList/EmptyListUIComponent.vue";

// *** Props ***
const props = defineProps({
    domainID: {
        type: [Number, null],
        required: true
    },
    domainName: {
        type: [String, null],
        required: true
    },
    dropdownOptions: {
        type: Array,
        required: true
    }
});

// *** Emits ***
const emits = defineEmits(["update"]);

// *** Reactive state ***
let activityList = reactive([]);

// *** Domain activity query ***
// #TODO 👉 add loader
const {
    isFetching: domainActivityIsFetching,
    isLoading: domainActivityIsLoading,
    refetch: refetchDomainActivity
} = useQuery({
    queryKey: ["domain-activity-query", props.domainID],
    queryFn: async () => domainActivityFn({ searchBy: "all", search: "", domainID: props.domainID, activePage: 1 }),
    enabled: () => !!props.domainID,
    onSuccess(data) {
        activityList = Object.assign(activityList, data.slice(0, 5));
    }
});

// *** Watch domain id and refetch domain activity query ***
watch(
    () => props.domainID,
    () => refetchDomainActivity()
);

// *** Handle dropdown update ***
const handleDropdownUpdate = (option) => {
    if (option !== props.domainName) emits("update", option);
};
</script>

<template>
    <div class="dashboard-main-queue">
        <div class="dashboard-main-queue-text-and-dropdown">
            <Typography variant="heading6" fontWeight="700">Queue Emails </Typography>
            <Typography
                v-if="props.dropdownOptions.length > 0 && props.domainName !== null"
                variant="heading6"
                fontWeight="700"
                margin="0"
            >
                <Dropdown
                    width="auto"
                    :text="props.domainName"
                    :options="props.dropdownOptions"
                    @update="handleDropdownUpdate"
                />
            </Typography>
        </div>

        <Loading v-if="domainActivityIsFetching || domainActivityIsLoading" margin="-39px 0 20px" />
        <div v-else-if="activityList.length > 0">
            <div class="dashboard-main-queue-items">
                <div class="dashboard-main-queue-item" v-for="activity in activityList" :key="activity.id">
                    <Image
                        src="assets/images/views/dashboard/send.svg"
                        alt="Share image"
                        margin="0 8px 0 0"
                        width="40px"
                        height="40px"
                    />
                    <Typography variant="body1">{{ activity.subject }}.</Typography>
                </div>
            </div>
            <Button size="small" :radius="6" margin="8px auto" @click="$router.push('/domains')">See all</Button>
        </div>
        <EmptyList v-else text="We don't have any activity yet" />
    </div>
</template>
