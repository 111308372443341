<script setup>
// *** Vue ***
import { ref, reactive } from "vue";

// *** Tanstack vue query ***
import { useMutation } from "@tanstack/vue-query";

// *** Toastify ***
import { toast } from "vue3-toastify/dist/index";

// *** Mixin ***
import { useGeneralMixin } from "@/mixin/general/useGeneralMixin";

// *** Mutation query ***
import { createTicketFn } from "@/api/dashbaord/mutations/dashboardAPIMutationsFn.js";

// *** General mixin ***
const { appHostName } = useGeneralMixin();

// *** Ref states ***
const question = ref("");
const description = ref("");

// *** Reactive states ***
const errorToggleHandling = reactive({
    question: false,
    description: false
});

const errorTextHandling = reactive({
    question: "",
    description: ""
});

// *** Create ticket mutation ***
const { isLoading: createTicketIsLoading, mutate: createTicketMutate } = useMutation({
    mutationKey: ["create-ticket-mutation"],
    mutationFn: createTicketFn,
    onSuccess() {
        toast.success("Your ticket was successfully created.");
        question.value = "";
        description.value = "";
    }
});

// *** Create ticket ***
const createTicket = () => {
    errorToggleHandling.question = false;
    errorToggleHandling.description = false;

    if (question.value.slice(" ").length < 1) {
        errorToggleHandling.question = true;
        errorTextHandling.question = "Your question must be at least 1 words";
    }

    if (description.value.slice(" ").length < 3) {
        errorToggleHandling.description = true;
        errorTextHandling.description = "Your description must be at least 3 words";
    }

    if (Object.values(errorToggleHandling).every((val) => val === false)) {
        createTicketMutate({
            title: question.value,
            text: description.value
        });
    }
};

// *** Extra data ***
const appName = appHostName.value.includes("127.0.0.1")
    ? "Mailerever"
    : String(appHostName.value[0]).toUpperCase() + String(appHostName.value[0]).slice(1);
</script>

<template>
    <DashboardContainer class="dashboard-support-form-and-box">
        <form @submit.prevent="createTicket" class="dashboard-support-form">
            <Input
                type="text"
                id="question-support-input"
                labelId="question-support-label"
                labelText="What's Happen?"
                :hasError="errorToggleHandling.question"
                :errorText="errorTextHandling.question"
                margin="16px 0 16px"
                v-model="question"
            />

            <Textarea
                id="tell-us-about-support-textarea"
                textareaName="description"
                labelId="tell-us-about-support-label"
                labelText="Tell Us About"
                :hasError="errorToggleHandling.description"
                :errorText="errorTextHandling.description"
                margin="16px 0 12.8px"
                v-model="description"
            />
            <Button type="submit" margin="0" :loading="createTicketIsLoading">Submit</Button>
        </form>
        <div class="dashboard-support-box">
            <Typography variant="heading6" align="center" lineHeight="1.75rem"
                >in
                {{ appName }}
                we want make you sure that we will help you to growth your business. <br />
                take it easy and give us all the work.💜</Typography
            >
            <Image
                src="assets/images/views/dashboard/user-support.svg"
                alt="Support Image"
                width="300px"
                height="300px"
                objectFit="unset"
            />
        </div>
    </DashboardContainer>
</template>
