<script setup>
// *** Vue ***
import { ref } from "vue";

// *** Email range slider ***
const emailRangeSlider = ref(1);

// *** Pricing table data ***
const pricingTable = [
    {
        id: 1,
        name: "Starter",
        price: "Free",
        icon: "light",
        services: ["99.5% guarnteed uptime SLA", "Standard IP pools", "Email support"],
        features: [
            "Email API, SMTP relay, Webhooks",
            "Suppression management",
            "HTML & rich text editor",
            "Bulk emailing",
            "Tracking and analytics",
            "Drag & drop builder"
        ],
        usageLimits: [
            "1 seat",
            "1 sending domain",
            "3 active templates",
            "7-day data retention",
            "1,000 daily API requests"
        ]
    },
    {
        id: 2,
        name: "Premium",
        price: "50$",
        icon: "flag",
        services: [
            "99.5% guarnteed uptime SLA",
            "Premium IP pool management",
            "Priority email support",
            "Live chat support",
            "Onboarding assistance",
            "Dedicated IP"
        ],
        features: [
            "Email API, SMTP relay, Webhooks",
            "Suppression management",
            "HTML & rich text editor",
            "Bulk emailing",
            "Inbound routing"
        ],
        usageLimits: [
            "20 seats",
            "50 sending domain",
            "250 active templates",
            "14-day data retention",
            "100,000+ daily API requests"
        ],
        pricing: [
            {
                monthly: 22,
                yearly: 260
            },
            {
                monthly: 22,
                yearly: 260
            },
            {
                monthly: 44,
                yearly: 519
            },
            {
                monthly: 108,
                yearly: 1296
            },
            {
                monthly: 216,
                yearly: 2592
            },
            {
                monthly: 324,
                yearly: 3888
            },
            {
                monthly: 418,
                yearly: 5012
            },
            {
                monthly: 594,
                yearly: 7128
            },
            {
                monthly: 792,
                yearly: 9504
            },
            {
                monthly: 990,
                yearly: 11880
            }
        ]
    },
    {
        id: 3,
        name: "Business Plus",
        price: "",
        icon: "crown",
        services: [
            "99.5% guarnteed uptime SLA",
            "Premium IP pool management",
            "Priority email support",
            "Live chat support",
            "Onboarding assistance",
            "Dedicated IP",
            "Deliverability consultation"
        ],
        features: [
            "Email API, SMTP relay, Webhooks",
            "Suppression management",
            "HTML & rich text editor",
            "Bulk emailing",
            "Inbound routing"
        ],
        usageLimits: [
            "∞ seats",
            "∞ sending domain",
            "∞ active templates",
            "30-day data retention",
            "100,000+ daily API requests"
        ],
        pricing: [
            {
                monthly: 55,
                yearly: 650
            },
            {
                monthly: 55,
                yearly: 650
            },
            {
                monthly: 110,
                yearly: 1298
            },
            {
                monthly: 270,
                yearly: 3240
            },
            {
                monthly: 540,
                yearly: 6480
            },
            {
                monthly: 810,
                yearly: 9720
            },
            {
                monthly: 1045,
                yearly: 12530
            },
            {
                monthly: 1485,
                yearly: 17820
            },
            {
                monthly: 1980,
                yearly: 23760
            },
            {
                monthly: 2475,
                yearly: 29700
            }
        ]
    }
];

// *** Email range items ***
const emailRangeItems = ["3K", "50K", "100K", "250K", "500K", "750K", "1M", "1.5M", "2M", "2.5M"];

// *** Handle input ***
const handleInput = (ev) => console.log();
</script>

<template>
    <DashboardContainer class="dashboard-cold-email-pricing">
        <div class="dashboard-cold-email-pricing-header">
            <Typography
                variant="heading6"
                align="center"
                lineHeight="1.75"
                fontWeight="normal"
                margin="8px 0 -16px"
                color="var(--third-text-color)"
            >
                How many emails do you plan to send?
            </Typography>

            <div class="dashboard-cold-email-pricing-slider">
                <div class="dashboard-cold-email-pricing-slider-items">
                    <div
                        v-for="(item, index) in emailRangeItems"
                        :key="index"
                        class="dashboard-cold-email-pricing-slider-item"
                        :class="emailRangeSlider === index + 1 && 'dashboard-cold-email-pricing-slider-active-item'"
                        v-text="item"
                    ></div>
                </div>

                <input
                    type="range"
                    min="1"
                    max="10"
                    step="1"
                    v-model.number="emailRangeSlider"
                    @input="handleInput"
                    class="dashboard-cold-email-pricing-range-slider"
                />
            </div>
        </div>

        <div class="dashboard-cold-email-pricing-container">
            <div
                class="dashboard-cold-email-pricing-content"
                v-for="(data, index) in pricingTable"
                :key="data.id"
                :class="data.id === 1 && emailRangeSlider !== 1 && 'dashboard-cold-email-pricing-disabled'"
            >
                <Icon
                    :name="data.icon"
                    width="60px"
                    height="60px"
                    margin="0 auto"
                    :background="data.id === 2 ? 'var(--third-color)' : 'var(--grey-color-1)'"
                    class="dashboard-cold-email-pricing-content-icon dashboard-cold-email-pricing-content-main-icon"
                    :color="data.id === 2 ? 'var(--main-color)' : 'var(--white-color)'"
                    padding="16px"
                    :radius="50"
                />

                <Typography
                    variant="heading4"
                    align="center"
                    margin="20px auto 32px"
                    color="var(--grey-color-2)"
                    class="dashboard-cold-email-pricing-content-name"
                    >{{ data.name }}</Typography
                >

                <div class="dashboard-cold-email-pricing-content-price">
                    <div class="dashboard-cold-email-pricing-content-price-monthly">
                        <Typography
                            variant="heading2"
                            margin="0"
                            fontWeight="normal"
                            align="center"
                            color="var(--third-text-color)"
                        >
                            <span v-html="data.price" v-if="index === 0"></span>
                            <span v-else>{{
                                data.pricing[emailRangeSlider - 1].monthly.toLocaleString("en-us") + "$"
                            }}</span>
                        </Typography>
                        <Typography variant="subtitle2" color="var(--third-text-color)"
                            ><span class="dashboard-cold-email-pricing-content-slash">/</span> month, billed
                            yearly</Typography
                        >
                    </div>

                    <div class="dashboard-cold-email-pricing-content-price-yearly" v-if="data.id !== 1">
                        <Typography variant="subtitle2" color="var(--third-text-color)"
                            >Monthly price x 12 =
                        </Typography>
                        <Typography variant="subtitle2" fontWeight="bold" color="var(--third-text-color)">
                            {{ data.pricing[emailRangeSlider - 1].yearly.toLocaleString("en-us") + "$" }}
                        </Typography>
                    </div>
                </div>

                <div class="dashboard-cold-email-pricing-content-services">
                    <Typography
                        variant="heading6"
                        margin="0 0 20px"
                        class="dashboard-cold-email-pricing-content-title dashboard-cold-email-pricing-content-services-title"
                    >
                        Services
                    </Typography>
                    <div
                        class="dashboard-cold-email-pricing-content-service"
                        v-for="(service, index) in data.services"
                        :key="index"
                    >
                        <Icon
                            class="dashboard-cold-email-pricing-content-icon"
                            name="check"
                            width="24px"
                            height="24px"
                            margin="0 12px 0 0"
                            padding="4.8px"
                            :radius="50"
                        />
                        <Typography variant="body1" class="dashboard-cold-email-pricing-content-service-name">{{
                            service
                        }}</Typography>
                    </div>
                </div>
                <div class="dashboard-cold-email-pricing-content-features">
                    <Typography
                        variant="heading6"
                        margin="0 0 20px"
                        class="dashboard-cold-email-pricing-content-title dashboard-cold-email-pricing-content-features-title"
                    >
                        Features
                    </Typography>
                    <div
                        class="dashboard-cold-email-pricing-content-feature"
                        v-for="(feature, index) in data.features"
                        :key="index"
                    >
                        <Icon
                            class="dashboard-cold-email-pricing-content-icon"
                            name="check"
                            width="24px"
                            height="24px"
                            margin="0 12px 0 0"
                            padding="4.8px"
                            :radius="50"
                        />

                        <Typography
                            variant="body1"
                            color="var(--third-text-color)"
                            class="dashboard-cold-email-pricing-content-feature-name"
                            >{{ feature }}</Typography
                        >
                    </div>
                </div>
                <div class="dashboard-cold-email-pricing-content-usage-limits">
                    <Typography
                        variant="heading6"
                        margin="0 0 20px"
                        class="dashboard-cold-email-pricing-content-title dashboard-cold-email-pricing-content-usage-limits-title"
                    >
                        Usage Limits
                    </Typography>
                    <div
                        class="dashboard-cold-email-pricing-content-usage-limit"
                        v-for="(usageLimit, index) in data.usageLimits"
                        :key="index"
                    >
                        <Icon
                            class="dashboard-cold-email-pricing-content-icon"
                            name="check"
                            width="24px"
                            height="24px"
                            margin="0 12px 0 0"
                            padding="4.8px"
                            :radius="50"
                        />

                        <Typography
                            variant="body1"
                            color="var(--third-text-color)"
                            class="dashboard-cold-email-pricing-content-usage-limit-name"
                            >{{ usageLimit }}</Typography
                        >
                    </div>
                </div>

                <Button
                    :fullWidth="true"
                    :radius="2"
                    margin="32px 0 16px"
                    :color="index === 1 ? 'var(--main-color)' : 'var(--grey-color-2)'"
                    :disabled="index === 0 && emailRangeSlider !== 1"
                    >Signup</Button
                >
            </div>
        </div>
    </DashboardContainer>
</template>

