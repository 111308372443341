<script setup>
// *** Vue ***
import { ref, reactive } from "vue";

// *** Tanstack vue query ***
import { useMutation } from "@tanstack/vue-query";

// *** Vue toastify ***
import { toast } from "vue3-toastify/dist/index";

// *** Mixin ***
import { useGeneralMixin } from "@/mixin/general/useGeneralMixin";

// *** Utils ***
import { emailRegex } from "@/utility/regex/regexUtility";

// *** Mutation functions(callbacks) ***
import { forgotPasswordFn } from "@/api/auth/mutations/authAPIMutationsFn";

// *** General mixin ***
const { appHostName } = useGeneralMixin();

// *** Ref states ***
const email = ref("");

// *** Reactive states ***
const errorToggleHandling = reactive({
    email: false
});

const errorTextHandling = {
    email: ""
};

// *** Forgot password mutation ***
const { isLoading: forgotPasswordLoading, mutate: forgotPasswordMutate } = useMutation({
    mutationKey: ["forgot-password-mutation"],
    mutationFn: forgotPasswordFn
});

// *** Forgot password form submit ***
const handleForgotPasswordForm = () => {
    // *** Reset error toggle handling ***
    errorToggleHandling.email = false;

    // *** Email validation ***
    if (emailRegex.test(email.value) === false) {
        errorToggleHandling.email = true;
        errorTextHandling.email = "Your email is not correct.";
    }

    if (errorToggleHandling.email === false) {
        forgotPasswordMutate(
            {
                email: email.value
            },
            {
                onSuccess() {
                    toast.success("A recovery email has been sent to you");
                }
            }
        );
    }
};
</script>

<template>
    <div class="auth-forgot-password">
        <Router-Link to="/" class="logo">
            <Image src="assets/images/logos/mailerever.svg" :alt="`${appHostName} logo`" width="60px" height="60px" />
            <Typography variant="heading5" color="var(--third-text-color)">{{ appHostName }}</Typography>
        </Router-Link>
        <div class="auth-forgot-password-container">
            <div class="auth-forgot-password-content">
                <div class="auth-forgot-password-header"></div>
                <div class="auth-forgot-password-inputs-and-buttons">
                    <div class="auth-forgot-password-icon-and-title">
                        <Icon name="unLock" width="24px" height="24px" margin="0 16px 0 0" />
                        <Typography variant="body2" color="var(--third-text-color)"
                            >Please enter your email and we will send you a link to reset your password</Typography
                        >
                    </div>
                    <Divider margin="28px auto 24px" height="3px" color="var(--background-color)" :radius="2" />
                    <form @submit.prevent="handleForgotPasswordForm" class="auth-forgot-password-form">
                        <Input
                            type="email"
                            id="forgot-password-email-input"
                            labelId="forgot-password-email-label"
                            labelText="Email Address"
                            inputColor="var(--third-text-color)"
                            :hasError="errorToggleHandling.email"
                            :errorText="errorTextHandling.email"
                            v-model="email"
                        />

                        <Button type="submit" :radius="5" margin="20px auto 16px" :loading="forgotPasswordLoading"
                            >Change Password</Button
                        >
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
