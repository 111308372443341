// *** Vue ***
import { computed } from "vue";

// *** Store ***
import { useStore } from "vuex";

export function useGeneralMixin() {
    // *** Vuex (store) instance ***
    const store = useStore();

    // *** General commit name ***
    const generalCommitName = store.state.general.commitName;

    // *** Dashboard commit name ***
    const dashboardCommitName = store.state.dashboard.commitName;

    // *** Screen width compute ***
    const screenWidth = computed(() => store.state.general.screenWidth);

    // *** App host name ***
    const appHostName = computed(() => {
        // *** Split host name by dot ***
        const splitHostName = location.hostname.split(".");

        // *** Show single word host name or 127.0.0.1 ***
        if (splitHostName.length === 1 || location.hostname.includes("127.0.0.1")) return location.hostname;

        // *** Show host name after join with . ***
        return splitHostName.slice(1, splitHostName.length - 1).join(".");
    });

    return {
        generalCommitName,
        dashboardCommitName,
        screenWidth,
        appHostName
    };
}
