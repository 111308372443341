<script setup>
// *** Vue ***
import { ref } from "vue";

// *** Tanstack vue query ***
// import { useQuery } from "@tanstack/vue-query";

// *** query funciton(callback) ***
// import { paymentListFn } from "@/api/dashbaord/queries/dashboardAPIQueriesFn";

// *** Components ***
import EmptyList from "@/components/UI/Utility/EmptyList/EmptyListUIComponent.vue";

// *** Ref states ***
const payments = ref([]);

// *** Payment list query ***
// useQuery({
//     queryKey: ['payment-list-query'],
//     queryFn: () => paymentListFn(),
//     onSuccess(data) {
//         payments.value = data;
//     }
// })
</script>

<template>
    <div class="dashboard-profile-payments-box">
        <div class="dashboard-profile-box-header">
            <Typography variant="subtitle2" color="var(--white-color)">Payments</Typography>
        </div>
        <Transition name="fade" mode="out-in">
            <div class="dashboard-profile-payments-items" v-if="payments.length > 0">
                <div class="dashboard-profile-payments-item" v-for="payment in payments" :key="payment.id">
                    <Typography variant="body1">{{ payment.date }}</Typography>
                    <Typography variant="subtitle2" :radius="1" padding="4px 8px" background="var(--third-color)"
                        >${{ payment.price }}</Typography
                    >
                </div>
            </div>
            <EmptyList text="We haven't any payments" v-else />
        </Transition>
    </div>
</template>
