// *** MIDDLEWARES ***
import MutationChangeState from "@/utility/mutationChangeState/mutationChangeStateUtility";

export default {
    namespaced: true,
    state: {
        commitName: "auth/authChangeState"
    },
    mutations: {
        authChangeState(state, payload) {
            MutationChangeState(state, payload);
        }
    }
};

