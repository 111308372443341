<script setup>
// *** Vue ***
import { ref, reactive } from "vue";

// *** Vue router ***
import { useRouter } from "vue-router";

// *** Tanstack vue query ***
import { useMutation } from "@tanstack/vue-query";

// *** Vue toastify ***
import { toast } from "vue3-toastify/dist/index";

// *** Mixin ***
import { useGeneralMixin } from "@/mixin/general/useGeneralMixin";

// *** Mutation functions(callbacks) ***
import { changePasswordFn } from "@/api/auth/mutations/authAPIMutationsFn";

// *** Router ***
const router = useRouter();

// *** General mixin ***
const { appHostName } = useGeneralMixin();

// *** Ref states ***
const password = ref("");
const repeatPassword = ref("");

// *** Reactive states ***
const errorToggleHandling = reactive({
    password: false,
    repeatPassword: false
});

const errorTextHandling = reactive({
    password: "",
    repeatPassword: ""
});

// *** Change password mutation ***
const { isLoading: changePasswordLoading, mutate: changePasswordMutate } = useMutation({
    mutationKey: ["sign-in-mutation"],
    mutationFn: changePasswordFn
});

// *** Change password form submit ***
const handleChangePasswordFormSubmit = () => {
    // *** Reset error toggle handling ***
    errorToggleHandling.password = false;
    errorToggleHandling.repeatPassword = false;

    // *** Password validation ***
    if (password.value.length < 8) {
        errorToggleHandling.password = true;
        errorTextHandling.password = "The password must be at least 8 characters long.";
    }

    // *** Password validation ***
    if (repeatPassword.value.length < 8) {
        errorToggleHandling.repeatPassword = true;
        errorTextHandling.repeatPassword = "The repeat password must be at least 8 characters long.";
    } else if (password.value !== repeatPassword.value) {
        errorToggleHandling.repeatPassword = true;
        errorTextHandling.repeatPassword = "Your password must match the repeated password.";
    }

    // *** Call api after validation is successful ***
    if (errorToggleHandling.password === false && errorToggleHandling.repeatPassword === false) {
        changePasswordMutate(
            {
                password: password.value
            },
            {
                onSuccess() {
                    toast.success("Congratulations! Your password has been successfully updated");
                    router.push({ name: "Auth/Signin" });
                }
            }
        );
    }
};
</script>

<template>
    <div class="auth-change-password">
        <Router-Link to="/" class="logo">
            <Image src="assets/images/logos/mailerever.svg" :alt="`${appHostName} logo`" width="60px" height="60px" />
            <Typography variant="heading5" color="var(--third-text-color)">{{ appHostName }}</Typography>
        </Router-Link>
        <div class="auth-change-password-container">
            <div class="auth-change-password-content">
                <!-- Change Password -->
                <div class="auth-change-password-header"></div>
                <div class="auth-change-password-inputs-and-buttons">
                    <div class="auth-forgot-password-icon-and-title">
                        <Icon name="unLock" width="24px" height="24px" margin="0 16px 0 0" />
                        <Typography variant="body2" color="var(--third-text-color)"
                            >Please enter your password and confirmation password and change password.</Typography
                        >
                    </div>
                    <Divider margin="28px auto 24px" height="3px" color="var(--background-color)" :radius="2" />

                    <form @submit.prevent="handleChangePasswordFormSubmit" class="auth-change-password-form">
                        <Input
                            type="password"
                            id="change-new-password"
                            labelId="change-new-password-label"
                            labelText="New password"
                            inputColor="var(--third-text-color)"
                            :hasError="errorToggleHandling.password"
                            :errorText="errorTextHandling.password"
                            v-model="password"
                        />

                        <Input
                            type="password"
                            id="change-repeat-password"
                            labelId="change-repeat-password-label"
                            labelText="Repeat password"
                            inputColor="var(--third-text-color)"
                            v-model="repeatPassword"
                            :hasError="errorToggleHandling.repeatPassword"
                            :errorText="errorTextHandling.repeatPassword"
                        />
                        <Button type="submit" :radius="5" margin="20px auto 16px" :loading="changePasswordLoading"
                            >Change Password</Button
                        >
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
