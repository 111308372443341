<script setup>
// *** Vue ***
import { reactive, ref } from "vue";

// *** Vue router ***
import { useRouter } from "vue-router";

// *** Tanstack vue query ***
import { useMutation } from "@tanstack/vue-query";

// *** Mixin ***
import { useGeneralMixin } from "@/mixin/general/useGeneralMixin";

// *** Utils ***
import { emailRegex } from "@/utility/regex/regexUtility";
import { setTokenStorage, setUserInfoStorage, setVerifyStorage } from "@/utility/storage/storageUtility";

// *** Mutation functions(callbacks) ***
import { signInMutationFn } from "@/api/auth/mutations/authAPIMutationsFn";

// *** Router ***
const router = useRouter();

// *** General mixin ***
const { appHostName } = useGeneralMixin();

// *** Ref states ***
const email = ref("");
const password = ref("");

// *** Reactivity states ***
const errorToggleHandling = reactive({
    username: false,
    password: false
});

const errorTextHandling = reactive({
    username: "",
    password: ""
});

// *** Signin mutation ***
const { isLoading: signInLoading, mutate: signInMutatate } = useMutation({
    mutationKey: ["sign-in-mutation"],
    mutationFn: signInMutationFn
});

// *** Signin form submit ***
const handleSigninForm = () => {
    // *** Reset error toggle handling ***
    errorToggleHandling.username = false;
    errorToggleHandling.password = false;

    // *** Email(username) validation ***
    if (emailRegex.test(email.value) === false) {
        errorToggleHandling.username = true;
        errorTextHandling.username = "Your email is not correct.";
    }

    // *** Password validation ***
    if (password.value.length < 8) {
        errorToggleHandling.password = true;
        errorTextHandling.password = "The password must be at least 8 characters long.";
    }

    // *** Call api after validation is successful ***
    if (!errorToggleHandling.username && !errorToggleHandling.password) {
        signInMutatate(
            {
                username: email.value,
                password: password.value
            },
            {
                onSuccess({ data }) {
                    // *** Save access and refresh token in localstorage ***
                    setTokenStorage(data.access_token, data.refresh_token);

                    // *** Save user information in localstorage ***
                    setUserInfoStorage(data.name, data.username, data.email);

                    // *** Save user is verified in localstorage ***
                    setVerifyStorage(data.is_verified);

                    // *** After save storage , redirect to main dashboard page ***
                    router.push({ name: "Dashboard/Main" });
                }
            }
        );
    }
};
</script>

<template>
    <div class="auth-signin">
        <Router-Link to="/" class="logo">
            <Image src="assets/images/logos/mailerever.svg" :alt="`${appHostName} logo`" width="60px" height="60px" />
            <Typography variant="heading5" color="var(--third-text-color)">{{ appHostName }}</Typography>
        </Router-Link>
        <div class="auth-signin-container">
            <div class="auth-signin-content">
                <div class="auth-signin-header"></div>
                <div class="auth-signin-inputs-and-buttons">
                    <form @submit.prevent="handleSigninForm" class="auth-signin-form">
                        <Input
                            type="email"
                            id="signin-email-input"
                            labelText="Email Address"
                            inputColor="var(--third-text-color)"
                            v-model="email"
                            :hasError="errorToggleHandling.username"
                            :errorText="errorTextHandling.username"
                        />

                        <Input
                            type="password"
                            id="signin-email-password"
                            labelId="signin-email-password-label"
                            labelText="Email password"
                            :hasError="errorToggleHandling.password"
                            :errorText="errorTextHandling.password"
                            inputColor="var(--third-text-color)"
                            v-model="password"
                        />

                        <Router-Link to="/auth/forgot-password" class="auth-signin-forgot-password-link"
                            >Forgot passord ?</Router-Link
                        >
                        <Button type="submit" :radius="5" margin="8px auto -8px" :loading="signInLoading"
                            >Sign in</Button
                        >
                    </form>
                    <div class="auth-signin-account-and-signup">
                        <Typography variant="subtitle1">Don't have an account yet?</Typography>
                        <router-link class="auth-signin-signup-link" to="/auth/signup">signup</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
