// *** Authentication view ***
import SignIn from "@/views/Auth/SignIn/SignInView.vue";
import SignUp from "@/views/Auth/SignUp/SignUpView.vue";
import ForgotPassword from "@/views/Auth/ForgotPassword/ForgotPasswordView.vue";
import ChangePassword from "@/views/Auth/ChangePassword/ChangePasswordView.vue";
// import Verification from "@/views/Auth/Verification/VerificationView.vue";

// *** Authentication routes ***
// # path 👉 url path 👉 loclahost:3000/auth/sigin
// # name 👉 route name for redirection 👉 router.push({ name: "Auth/Signin" })
// # component 👉 when we go localhsot:3000/auth/sigin execute this component ***
const authRoutes = [
    {
        path: "/auth/signin",
        name: "Auth/Signin",
        component: SignIn
    },
    {
        path: "/auth/signup",
        name: "Auth/Signup",
        component: SignUp
    },
    {
        path: "/auth/forgot-password",
        name: "Auth/Forgot-Password",
        component: ForgotPassword
    },
    {
        path: "/auth/change-password/:strCode",
        name: "Auth/Change-Password",
        component: ChangePassword
    }
    // {
    //     path: "/auth/verification",
    //     name: "Auth/Verification",
    //     component: Verification
    // }
];

export default authRoutes;

