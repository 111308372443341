<template>
    <div class="not-found-error-view-container">
        <Image src="assets/images/views/404/not-found.svg" alt="not-found" width="370px" height="370px" />
        <Typography variant="heading3" margin="-16px 0 0">OOPS! NOTING WAS FOUND</Typography>
        <Typography variant="subtitle1" :inline="true" color="var(--main-text-color)">
            The page your are looking for might have been removed hat its name changed or is temporarily unavailable.
            <Router-Link to="/">Go To Index</Router-Link>
        </Typography>
    </div>
</template>

