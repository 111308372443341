// *** Vue query ***
import { QueryClient } from "@tanstack/vue-query";

// Create a QueryClient instance with default options
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

export default queryClient;
