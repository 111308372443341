<script setup>
// *** Vue ***
import { ref } from "vue";

// *** Components ***
import CodeEditor from "simple-code-editor";

// *** Ref states ***
const activeCodeValue = ref("cURL");

const changeLanguage = (lang) => {
    activeCodeValue.value = lang;
};

// *** Extra data ***
const codeValues = {
    cURL: `curl --url 'smtps://smtp.gmail.com:465' --ssl-reqd \n--mail-from 'username@gmail.com' --mail-rcpt 'john@example.com' \n--mail-rcpt 'mary@gmail.com' --mail-rcpt 'eli@example.com' \n--upload-file mail.txt --user 'username@gmail.com:password' --insecure`,
    PHP: `curl --url 'smtps://smtp.gmail.com:465' --ssl-reqd \n--mail-from 'username@gmail.com' --mail-rcpt 'john@example.com' \n--mail-rcpt 'mary@gmail.com' --mail-rcpt 'eli@example.com' \n--upload-file mail.txt --user 'username@gmail.com:password' --insecure`,
    Laravel: `curl --url 'smtps://smtp.gmail.com:465' --ssl-reqd \n--mail-from 'username@gmail.com' --mail-rcpt 'john@example.com' \n--mail-rcpt 'mary@gmail.com' --mail-rcpt 'eli@example.com' \n--upload-file mail.txt --user 'username@gmail.com:password' --insecure`,
    Java: `curl --url 'smtps://smtp.gmail.com:465' --ssl-reqd \n--mail-from 'username@gmail.com' --mail-rcpt 'john@example.com' \n--mail-rcpt 'mary@gmail.com' --mail-rcpt 'eli@example.com' \n--upload-file mail.txt --user 'username@gmail.com:password' --insecure`,
    Ruby: `curl --url 'smtps://smtp.gmail.com:465' --ssl-reqd \n--mail-from 'username@gmail.com' --mail-rcpt 'john@example.com' \n--mail-rcpt 'mary@gmail.com' --mail-rcpt 'eli@example.com' \n--upload-file mail.txt --user 'username@gmail.com:password' --insecure`,
    "Node.js": `curl --url 'smtps://smtp.gmail.com:465' --ssl-reqd \n--mail-from 'username@gmail.com' --mail-rcpt 'john@example.com' \n--mail-rcpt 'mary@gmail.com' --mail-rcpt 'eli@example.com' \n--upload-file mail.txt --user 'username@gmail.com:password' --insecure`,
    Go: `curl --url 'smtps://smtp.gmail.com:465' --ssl-reqd \n--mail-from 'username@gmail.com' --mail-rcpt 'john@example.com' \n--mail-rcpt 'mary@gmail.com' --mail-rcpt 'eli@example.com' \n--upload-file mail.txt --user 'username@gmail.com:password' --insecure`,
    Python: `curl --url 'smtps://smtp.gmail.com:465' --ssl-reqd \n--mail-from 'username@gmail.com' --mail-rcpt 'john@example.com' \n--mail-rcpt 'mary@gmail.com' --mail-rcpt 'eli@example.com' \n--upload-file mail.txt --user 'username@gmail.com:password' --insecure`
};

const languages = ["cURL", "PHP", "Laravel", "Java", "Ruby", "Node.js", "Go", "Python"];
</script>

<template>
    <DashboardContainer class="dashboard-domain-management-api-instruductions">
        <Typography variant="heading4" color="var(--secondary-text-color)">API instructions</Typography>
        <div class="dashboard-domain-management-api-instruductions-content">
            <div class="code-editor-tabs">
                <div
                    class="code-editor-tab"
                    v-for="(language, index) in languages"
                    :key="index"
                    v-text="language"
                    @click="changeLanguage(language)"
                    :class="language == activeCodeValue && 'code-editor-tab-active'"
                ></div>
            </div>
            <CodeEditor
                class="atom_one_dark"
                :read_only="true"
                :value="codeValues[activeCodeValue]"
                width="100%"
                :languages="languages"
                :language_selector="false"
                :wrap_code="true"
                font_size="16px"
                border_radius="0 0 8px 8px"
            ></CodeEditor>
        </div>
    </DashboardContainer>
</template>

