<script setup>
// *** Mixin ***
import { useGeneralMixin } from "@/mixin/general/useGeneralMixin";

// *** General mixin ***
const { screenWidth } = useGeneralMixin();
</script>

<template>
    <div class="utility-wave">
        <Image
            src="assets/images/views/dashboard/wave.svg"
            alt="wave effect"
            :width="`${screenWidth}px`"
            height="100%"
            margin="0 0 -7px"
        />
    </div>
</template>
