// *** Directives ***
import ClickOutside from "./Events/clickOutsideDirective";
import Resize from "./Events/resizeDirective";
import Scroll from "./Events/scrollDirective";
import ClickEscapeButton from "./Events/clickEscapeButtonDirective";

// *** Create object from imported directive ***
const directives = {
    resize: Resize,
    scroll: Scroll,
    "click-outside": ClickOutside,
    "click-escape-button": ClickEscapeButton
};

// *** Create register directive for add directive to vue instance ***
const RegisterDirectives = (app) => {
    Object.entries(directives).forEach(async ([name, method]) => {
        app.directive(name, method);
    });
    return app;
};

export default RegisterDirectives;

